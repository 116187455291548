import React, { useState, useEffect } from 'react'
import { TableCell, TableRow, Button } from '@mui/material'
import { Session, User } from '../../types'
import {
	militaryToStandard,
	dateYMDdashToMDYslash,
	formatDateStringToYMD,
	dateObjToDateStrDash,
	dateStringToDateObj,
} from '../../utils/timeConversion'
import { patchParticipant } from '../../services/server'

interface SelfVerificationRowProps {
	session: Session
	user: User
}

const isDisplayedSession = (date: string, currentDate: string, verified: boolean) => {
	return (
		dateStringToDateObj(date).getDate() === new Date().getDate() ||
		(dateStringToDateObj(date).getDate() < new Date().getDate() && !verified)
	)
}

const SelfVerificationRow: React.FC<SelfVerificationRowProps> = ({ session, user }) => {
	const [verified, setVerified] = useState(false)
	const [participantId, setParticipantId] = useState('')
	const currentDate = process.env.REACT_APP_DATE || dateObjToDateStrDash()

	useEffect(() => {
		if ('participants' in session && session.participants.length > 0) {
			// TODO: remove conditional after multiday refactor
			const participant = session.participants.filter((student) => {
				return student.lmsId === user.lmsId
			})[0]
			if (participant === undefined) return

			setVerified(participant.isSelfVerified)
			participant && setParticipantId(participant._id)
		}
	}, [session, user.lmsId])

	const confirmAttendance = () => {
		if (verified) {
			return
		}
		if (!session._id) return
		const selfVerifiedDate = new Date().toString()
		patchParticipant(
			participantId,
			{ isSelfVerified: true, selfVerifiedDate, whoVerified: user.lmsId },
			session._id
		)
		setVerified(true)
	}

	const background = verified ? '#9e9e9e' : '#7BA7BC'
	if (!isDisplayedSession(session.date, currentDate, verified)) return null
	return (
		<TableRow>
			<TableCell>{session.name}</TableCell>
			<TableCell align="center">{session.lmsId}</TableCell>
			<TableCell align="center">{militaryToStandard(session.startTime)}</TableCell>
			<TableCell align="center">{dateYMDdashToMDYslash(session.date)}</TableCell>
			<TableCell align="center">
				<Button
					onClick={confirmAttendance}
					type="button"
					variant="contained"
					sx={{ minWidth: '200px', color: 'black', backgroundColor: background }}
				>
					{verified ? 'Confirmed' : 'Confirm Attendance'}
				</Button>
			</TableCell>
		</TableRow>
	)
}

export default SelfVerificationRow
