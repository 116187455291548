import { useCallback } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { Session } from '../../types'
import { activeSessionIdState, activeSessionState } from '../sessions'
import { patchSession } from '../server'

export const useActiveSession = () => {
	const [activeSession, setActiveSession] = useRecoilState(activeSessionState)
	const setActiveSessionId = useSetRecoilState(activeSessionIdState)

	const updateActiveSession = useCallback(
		(updateValues: Partial<Session>) => {
			setActiveSession((currentActiveSession: Session) => {
				console.log('running here')
				if (currentActiveSession._id) patchSession(currentActiveSession._id, updateValues)
				return { ...currentActiveSession, ...updateValues }
			})
		},
		[setActiveSession]
	)

	return { activeSession, setActiveSessionId, updateActiveSession }
}
