import { Box, Select, MenuItem, Button, CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSetRecoilState, useRecoilState } from 'recoil'
import { useActiveSession } from '../services/hooks/useActiveSession'
import { getMultiDayBySessionsId, patchSession } from '../services/server'
import { sessionListState, activeSessionIdState } from '../services/sessions'
import { Session } from '../types'
import { dateObjToDateStrDash, formatDateStringToYMD } from '../utils/timeConversion'
import Modal from './Modal'

interface MultidayDropDownProps {
	selectedSession: string
	setSelectedSession: React.Dispatch<React.SetStateAction<string>>
	currentPage: string
}

const MultidayDropDown: React.FC<MultidayDropDownProps> = ({
	selectedSession,
	setSelectedSession,
	currentPage,
}) => {
	const { activeSession } = useActiveSession()
	const [sessionList, setSessionList] = useRecoilState(sessionListState)
	const [loading, setLoading] = useState(false)
	const setActiveSessionId = useSetRecoilState(activeSessionIdState)
	const { date, lmsId } = activeSession
	const currentDate = process.env.REACT_APP_DATE || dateObjToDateStrDash()
	const [displayModal, setDisplayModal] = useState<boolean>(false)

	useEffect(() => {
		getMultiDayBySessionsId(lmsId).then((multidaySessions: Session[]) => {
			setSessionList(multidaySessions)
			if (!activeSession._id) return
			// Need to add setActiveSessionId in order to pull from new sessionList
			setActiveSessionId(activeSession._id)
		})
	}, [activeSession._id, lmsId, setActiveSessionId, setSessionList])

	useEffect(() => {
		setSelectedSession(lmsId)
	}, [date, setSelectedSession])

	const onDateChange = (event: { target: { value: string } }) => {
		const newSelectedSession = sessionList.filter(
			(session) => session.lmsId === event.target.value
		)[0]

		const newSessionId = newSelectedSession._id
		if (newSessionId) {
			// Have to set a new active session when date is changed
			setActiveSessionId(newSessionId)
			setSelectedSession(newSelectedSession.lmsId)
		}
	}

	const getMultidaySessionsDates = () => {
		return sessionList.map((session) => {
			if (session.date > formatDateStringToYMD(currentDate)) return null
			return (
				<MenuItem key={session.date + session.lmsId} value={session.lmsId}>
					{session.date}: Session {session.lmsId.split('_')[1]}
				</MenuItem>
			)
		})
	}

	const submitSelectedDateAttendance = () => {
		setLoading(true)
		console.log('SUBMITTING ATTENDANCE FOR ', activeSession?._id as string)
		// True refers to setting isMultiday to true
		patchSession(activeSession?._id as string, {}, false, true)
		setLoading(false)
		setDisplayModal(true)
	}

	const closeModal = () => {
		setDisplayModal(false)
	}

	return (
		<>
			{displayModal && (
				<Modal
					title="Sucess"
					message="Attendance was submitted"
					updateParent={closeModal}
				/>
			)}
			<Box sx={{ display: 'flex', justifyContent: 'center' }}>
				<Box sx={{ margin: '10px', width: '25%' }}>
					<Select
						value={selectedSession}
						onChange={onDateChange}
						sx={{
							flexGrow: 1,
							marginRight: '10px',
							width: '100%',
							bgcolor: 'primary.main',
							color: 'white',
						}}
					>
						{getMultidaySessionsDates()}
					</Select>
				</Box>
				{sessionList[sessionList.length - 1].lmsId !== lmsId &&
					currentPage === 'Attendance' && (
						<Box sx={{ margin: '10px', width: '25%' }}>
							<Button
								variant="contained"
								sx={{
									flexGrow: 1,
									marginRight: '10px',
									width: '100%',
									bgcolor: 'green',
									color: 'white',
									height: '100%',
								}}
								onClick={() => {
									submitSelectedDateAttendance()
								}}
								disabled={loading}
							>
								{loading ? <CircularProgress /> : 'Submit Attendance'}
							</Button>
						</Box>
					)}
			</Box>
		</>
	)
}

export default MultidayDropDown
