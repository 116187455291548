import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
}

const BasicModal: React.FC<{
	title: string
	message: string | React.ReactElement
	updateParent: Function
	errorList?: { lmsId: string; errorMessage: string }[]
}> = ({ title = 'title', message = 'message', updateParent, errorList }): JSX.Element => {
	const [open, setOpen] = React.useState(true)
	const handleClose = () => {
		updateParent(false)
		setOpen(false)
	}

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<Typography id="modal-modal-title" variant="h6" component="h2">
					{title}
				</Typography>
				<Box id="modal-modal-description" sx={{ mt: 2 }}>
					{message}
					{errorList && (
						<ul>
							{errorList.map((error) => {
								{
									console.log('Modal error', error)
								}
								return (
									<li key={error.lmsId}>
										{error.lmsId}: {error.errorMessage}
									</li>
								)
							})}
						</ul>
					)}
				</Box>
			</Box>
		</Modal>
	)
}

export default BasicModal
