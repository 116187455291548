import React, { useCallback, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useNavigate } from 'react-router'
import { Box, Container, Button, TextField } from '@mui/material'
import HandoffVerificationConfirmation from './HandoffVerificationConfirmation'
import BottomBar from '../../components/BottomBar'
import Modal from '../../components/Modal'
import { sessionListState, activeSessionIdState } from '../../services/sessions'

const HandoffVerification = () => {
	const navigate = useNavigate()
	const sessionList = useRecoilValue(sessionListState)
	const setActiveSessionId = useSetRecoilState(activeSessionIdState)

	const [employeeData, setEmployeeData] = useState({
		id: '',
		firstName: '',
		lastName: '',
		classPin: '',
		className: '',
		classTime: '',
		_id: '',
	})
	const [employeeFound, setEmployeeFound] = useState<boolean>(false)
	const [error, setError] = useState<string | null>(null)

	const toSessionSelection = useCallback(() => {
		navigate('/')
	}, [navigate])

	const closeErrorModal = () => {
		setError(null)
	}

	const searchForEmployee = (): void => {
		if (employeeData.classPin === '' || employeeData.id === '')
			return setError('Missing Required Fields')
		const correctSession = sessionList.filter(
			(session) => session.lmsId === employeeData.classPin
		)[0]
		if (!correctSession) return setError('Session Not Found')

		const correctParticipants = correctSession.participants.filter(
			(employee) => employee.lmsId === employeeData.id
		)[0]
		if (!correctParticipants) return setError('Employee Not Found')
		// if nothing fails and correct employee, we display the confirm attendance
		setActiveSessionId(`${correctSession._id}`)
		setEmployeeData({
			...employeeData,
			_id: correctParticipants._id,
			firstName: correctParticipants.firstName,
			lastName: correctParticipants.lastName,
			className: correctSession.name,
			classTime: `${correctSession.time} ${correctSession.date}`,
		})
		return setEmployeeFound(true)
	}

	return (
		<Container
			disableGutters
			sx={{
				paddingTop: '40px',
				display: 'flex',
				flexDirection: 'column',
				'.MuiBox-root:nth-of-type(even)': { bgcolor: '#eee' },
				'label.MuiBox-root': { width: '15%', ml: '5%' },
				'.MuiFormControl-root': { width: '75%', margin: '10px' },
				'#input-box': {
					display: 'flex',
					alignItems: 'center',
					width: '100%',
				},
				'#display-box': {
					width: '75%',
					padding: '26.5px 14px',
				},
				'#button': { display: 'flex', justifyContent: 'center' },
			}}
		>
			{error && <Modal title="Error" message={error} updateParent={closeErrorModal} />}

			<Box id="input-box">
				<Box component="label">* User ID:</Box>
				<TextField
					value={employeeData.id}
					onChange={(e) =>
						setEmployeeData({
							...employeeData,
							id: e.target.value,
						})
					}
				/>
			</Box>

			<Box id="input-box" sx={{ mb: '10px' }}>
				<Box component="label">* Class Pin:</Box>
				<TextField
					value={employeeData.classPin}
					onChange={(e) =>
						setEmployeeData({
							...employeeData,
							classPin: e.target.value,
						})
					}
				/>
			</Box>

			<Box id="button">
				<Button variant="contained" onClick={searchForEmployee} sx={{ minWidth: '200px' }}>
					Find Class:
				</Button>
			</Box>
			{employeeFound && <HandoffVerificationConfirmation employeeData={employeeData} />}

			<BottomBar
				instructions=""
				buttons={[{ label: 'Cancel', onClick: toSessionSelection }]}
			/>
		</Container>
	)
}

export default HandoffVerification
