import { Button } from '@mui/material'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router'
import checkmark from '../../assets/check-mark.png'
import InformationBar from '../../components/InformationBar'
import ReportButton from '../../components/ReportButton'

const SubmissionComplete: React.FC = () => {
	const navigate = useNavigate()

	const toAttachAssessment = useCallback(() => {
		navigate('/attachAssessment')
	}, [navigate])

	const toAttendance = useCallback(() => {
		navigate('/')
	}, [navigate])

	return (
		<div>
			<InformationBar currentPage="Submission Complete" displayClass={true} />
			<div
				style={{
					marginTop: '1%',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<img src={checkmark} alt="checkmark" width="35%" />
				<h1 style={{ fontSize: '3em', padding: '20px' }}>
					Is there a Paper Based Assessment?
				</h1>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
				}}
			>
				<Button
					variant="contained"
					onClick={toAttachAssessment}
					sx={{ minWidth: '200px', marginRight: '150px' }}
				>
					YES
				</Button>
				<Button variant="contained" onClick={toAttendance} sx={{ minWidth: '200px' }}>
					NO
				</Button>
				{/* <ReportButton /> */}
			</div>
			<div
				style={{
					backgroundColor: '#eee',
					marginTop: '95px',
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<p>
					Your roster has been successfully submitted. An email of the summary has been
					sent to you for your records.
				</p>
			</div>
		</div>
	)
}

export default SubmissionComplete
