import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { authState, AUTH_STATUS } from '../../services/authentication'
import { userState } from '../../services/user'
import { server } from '../../services/server'

const Reauthenticate: React.FC<{ className?: string }> = ({ className }) => {
	const [auth, setAuth] = useRecoilState(authState)
	const setUser = useSetRecoilState(userState)
	const navigate = useNavigate()

	useEffect(() => {
		switch (auth.status) {
			case AUTH_STATUS.CHECKING_REAUTHORIZATION:
				server
					.reAuthenticate()
					.then(({ user: jwtuser }) => {
						setAuth({
							userId: jwtuser._id,
							status: AUTH_STATUS.AUTHENTICATED,
						})
						setUser({ ...jwtuser })
					})
					.catch((e) => {
						setAuth({
							userId: '',
							status: AUTH_STATUS.REQUIRES_LOGON,
						})
					})
				break
			case AUTH_STATUS.AUTHENTICATED:
				navigate('/')
				break
			case AUTH_STATUS.REQUIRES_LOGON:
				navigate('/auth/login')
		}
	}, [navigate, auth.status, setAuth, setUser])

	return null
}
export default Reauthenticate
