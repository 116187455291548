import React, { useState, useCallback, useEffect } from 'react'
import { Container, TextField, Box } from '@mui/material'
import { useNavigate } from 'react-router'
import SearchSharpIcon from '@mui/icons-material/SearchSharp'
import { useActiveSession } from '../../services/hooks/useActiveSession'
import BottomBar from '../../components/BottomBar'
import InformationBar from '../../components/InformationBar'
import Spinner from '../../components/Spinner'
import { Participant, Session } from '../../types'
import Modal from '../../components/Modal'
import { addParticipant, getMultiDayBySessionsId, getParticipant } from '../../services/server'

const WalkIn: React.FC = () => {
	const { activeSession } = useActiveSession()
	const [displayModal, setDisplayModal] = useState('')
	const [loading, setLoading] = useState(false)
	const [errorList, setErrorList] = useState<{ lmsId: string; errorMessage: string }[]>([])
	const [doneSubmitting, setDoneSubmitting] = useState(false)
	const newWalkingIndex = (activeSession as Session).participants?.length || 0
	const currentParticipantsByLmsId = activeSession.participants.map(
		(participant: Participant) => {
			return participant.lmsId
		}
	)

	const [newWalkIn, setWalkIn] = useState({
		firstName: '',
		lastName: '',
		lmsId: '',
		walkIn: true,
		completion: 0,
		attendance: false,
		isSelfVerified: false,
		selfVerifiedDate: '',
		whoVerified: '',
		_id: `${activeSession._id}_${newWalkingIndex}`,
	})

	useEffect(() => {
		if (errorList.length > 0) {
			setDisplayModal(`Error adding users in:`)
		}
	}, [errorList])

	const navigate = useNavigate()

	const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setWalkIn({ ...newWalkIn, [event.target.id]: event.target.value })
	}

	const onIdUpdate = async (value: string) => {
		if (value) {
			setLoading(true)
			getParticipant(value)
				.then((res: [{ firstName: string; lastName: string }]) => {
					if (res?.length) {
						setLoading(false)
						setWalkIn({
							...newWalkIn,
							firstName: res[0]?.firstName,
							lastName: res[0]?.lastName,
						})
					} else if (res === undefined) {
						setDisplayModal('notConnected')
						setLoading(false)
					} else {
						setDisplayModal('User not found')
						setLoading(false)
					}
				})
				.catch((err: Error) => {
					setLoading(false)
					throw err
				})
		}
	}

	const onCancel = () => {
		navigate('/attendance')
	}

	const closeModal = () => {
		setDisplayModal('')
		setErrorList([])
	}

	const onConfirm = useCallback(() => {
		if (!activeSession._id) return
		if (currentParticipantsByLmsId.includes(newWalkIn.lmsId)) {
			setDisplayModal('User is already enrolled in class')
			return
		}

		const addMultidayClass = async (walkIn: Participant, lmsId: string) => {
			const multidaySessions = await getMultiDayBySessionsId(lmsId)

			multidaySessions.map((session, index) => {
				if (!session._id) return null
				// Student was unable to be enrolled in
				return addParticipant(walkIn, session._id).then(
					(data: { error: boolean; message: string | { message: string } }) => {
						if (data.error) {
							setErrorList((prevError) => {
								return [
									...prevError,
									{
										lmsId: session.lmsId,
										errorMessage:
											typeof data.message === 'string'
												? data.message
												: data.message.message,
									},
								]
							})
						}
						if (multidaySessions.length - 1 === index && errorList.length === 0) {
							return navigate('/attendance')
						}
						return null
					}
				)
			})
		}

		if (activeSession.lmsId.includes('_')) {
			addMultidayClass(newWalkIn, activeSession.lmsId)
		} else {
			addParticipant(newWalkIn, activeSession._id).then(
				(data: { error: boolean; message: string }) => {
					if (data.error) {
						if (data.message.includes('Schedule ID is required')) {
							setDisplayModal(
								'Class Conversion in Aspire is not complete, therefore you cannot add participants. Once the conversion is complete and Class ID changes from Red font to Black font, you will be able to add participants. This may take up to an hour to complete.'
							)
						} else {
							setDisplayModal(data.message)
						}
					} else {
						navigate('/attendance')
					}
				}
			)
		}
		// navigate('/attendance')
	}, [activeSession._id, activeSession.lmsId, currentParticipantsByLmsId, navigate, newWalkIn])

	const checkName = () => {
		if (newWalkIn.firstName.length > 1) {
			return false
		}
		return true
	}

	const boxStyle = {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		margin: '0px auto',
	}
	const labelStyle = { minWidth: '15%' }

	return (
		<div style={{ margin: '0px auto' }}>
			<Container disableGutters>
				{displayModal !== '' && (
					<Modal
						title="Error"
						message={displayModal}
						updateParent={closeModal}
						errorList={errorList}
					/>
				)}
				<InformationBar currentPage="Add Walk-In" displayClass={true} />

				<Box sx={{ padding: '40px' }}>
					{loading && <Spinner />}
					<Box sx={{ ...boxStyle, position: 'relative' }}>
						<div style={labelStyle}>User ID:</div>
						<TextField
							className="fullWidth"
							id="lmsId"
							value={newWalkIn.lmsId}
							onChange={onInputChange}
							disabled={loading}
							onBlur={(event) => {
								onIdUpdate(event.target.value)
							}}
							onKeyDown={(event) => {
								const target = event.target as HTMLTextAreaElement
								if (event.key === 'Enter') {
									onIdUpdate(target.value)
								}
							}}
						/>
						<SearchSharpIcon
							sx={{ position: 'absolute', right: '30px', cursor: 'pointer' }}
						/>
					</Box>
					<Box sx={boxStyle}>
						<div style={labelStyle}>First Name:</div>
						<TextField
							className="fullWidth"
							id="firstName"
							disabled
							value={newWalkIn.firstName}
							onChange={onInputChange}
							sx={{ backgroundColor: 'lightgray' }}
						/>
					</Box>
					<Box sx={boxStyle}>
						<div style={labelStyle}>Last Name:</div>
						<TextField
							className="fullWidth"
							id="lastName"
							disabled
							value={newWalkIn.lastName}
							onChange={onInputChange}
							sx={{ backgroundColor: 'lightgray' }}
						/>
					</Box>
				</Box>
			</Container>
			<div style={{ backgroundColor: '#eee', padding: '.5em' }}>
				Add User ID using only digits (i.e., 123456, not U23456).
			</div>
			<BottomBar
				instructions=""
				buttons={[
					{ label: 'Cancel', onClick: onCancel },
					{ label: 'Confirm', onClick: onConfirm, disabled: checkName() },
				]}
			/>
		</div>
	)
}

export default WalkIn
