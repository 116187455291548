import React, { useEffect } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router'
import { Container } from '@mui/material'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { useForceAuthentication } from '../services/authentication'
import AuthRoutes from './Auth/AuthRoutes'
import TopBar from '../components/TopBar'
import Footer from '../components/Footer'
import Attendance from './Attendance'
import SessionSelection from './SessionSelection'
import ClassCreation from './ClassCreation'
import SessionDetails from './SessionDetails'
import CompletionRecord from './CompletionRecord'
import SubmissionComplete from './SubmissionComplete'
import SummaryReview from './SummaryReview'
import WalkIn from './WalkIn'
import AttachAssessment from './AttachAssessment'
import HandoffVerification from './HandoffVerification'
import SelfVerification from './SelfVerification'
import InstructorSelfVerification from './InstructorSelfVerification'
import Reports from './Reports'
import { userState } from '../services/user'
import { activeSessionIdState, sessionStateFamily } from '../services/sessions'
import { Session } from '../types'
import { server } from '../services/server'
import SessionSummary from './SessionSummary'

const App: React.FC<{ className?: string }> = ({ className }) => {
	useForceAuthentication()
	const navigate = useNavigate()
	const user = useRecoilValue(userState)
	const [activeSessionId, setActiveSessionId] = useRecoilState(activeSessionIdState)
	const setSessionStateFamily = useSetRecoilState(sessionStateFamily(activeSessionId))

	useEffect(() => {
		if (user.roles.includes('student') && !user.roles.includes('instructor'))
			navigate('/selfVerification')
	}, [navigate, user.roles])

	useEffect(() => {
		const updateActiveSession = (session: Session) => {
			if (session._id === activeSessionId) {
				// Update session
				setSessionStateFamily(session)
				setActiveSessionId(activeSessionId)
			}
		}
		server.service('sessions').on('updated', updateActiveSession)
		server.service('sessions').on('patched', updateActiveSession)
		return () => {
			server.service('sessions').off('updated', updateActiveSession)
			server.service('sessions').off('patched', updateActiveSession)
		}
	})

	return (
		<Container disableGutters>
			<TopBar />
			<Routes>
				<Route path="/" element={<Navigate to="/selfVerification" />} />
				<Route path="/sessionSelection" element={<SessionSelection />} />
				<Route path="/attendance" element={<Attendance />} />
				<Route path="/sessionDetails" element={<SessionDetails />} />
				<Route path="/walkIn" element={<WalkIn />} />
				<Route path="/completion" element={<CompletionRecord />} />
				<Route path="/submissionComplete" element={<SubmissionComplete />} />
				<Route path="/summary" element={<SummaryReview />} />
				<Route path="/attachAssessment" element={<AttachAssessment />} />
				<Route path="/verification" element={<HandoffVerification />} />
				<Route path="/auth/*" element={<AuthRoutes />} />
				<Route path="/classCreation" element={<ClassCreation />} />
				<Route path="/sessionSummary" element={<SessionSummary />} />
				<Route path="/selfVerification" element={<SelfVerification />} />
				<Route
					path="/instructorSelfVerification"
					element={<InstructorSelfVerification />}
				/>
				<Route path="/reports" element={<Reports />} />
			</Routes>
			<Footer />
		</Container>
	)
}

export default App
