import React, { useEffect, useState } from 'react'
import {
	Box,
	Button,
	Container,
	CssBaseline,
	Grid,
	TextField,
	Typography,
	Link,
} from '@mui/material'
import { useRecoilValue } from 'recoil'
import { useNavigate } from 'react-router'
import { validateEmail } from '../../utils/validatation'
import { authState, AUTH_STATUS, useAccountCreation } from '../../services/authentication'

const Signup: React.FC<{ className?: string }> = ({ className }) => {
	const auth = useRecoilValue(authState)
	const [errorMessage, setErrorMessage] = useState('')
	const createAccount = useAccountCreation()
	const navigate = useNavigate()

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		const firstName = data.get('firstName')?.toString() || ''
		const lastName = data.get('lastName')?.toString() || ''
		const email = data.get('email')?.toString().toLowerCase() || ''
		const password = data.get('password')?.toString() || ''

		if (firstName.length < 1) {
			setErrorMessage('First Name Required.')
			return
		}
		if (lastName.length < 1) {
			setErrorMessage('Last Name Required.')
			return
		}
		if (!validateEmail(email)) {
			setErrorMessage('Invalid Email Address.')
			return
		}
		if (password.length < 3) {
			setErrorMessage('Invalid Password.')
			return
		}

		if (auth.status === AUTH_STATUS.AUTHENTICATION_FAILED)
			setErrorMessage(`You've entered the incorrect Email or Password. Please try again.`)
		else setErrorMessage('')

		createAccount({ firstName, lastName, email, password })
	}

	useEffect(() => {
		if (auth.status === AUTH_STATUS.NEW_ACCOUNT) navigate('/auth/login')
	}, [auth.status, navigate])

	return (
		<div data-test="Signup" className={`Signup ${className || ''}`}>
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<Box
					sx={{
						marginTop: 8,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Typography component="h1" variant="h5">
						Sign up
					</Typography>
					<Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<TextField
									autoComplete="given-name"
									name="firstName"
									required
									fullWidth
									id="firstName"
									label="First Name"
									autoFocus
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									required
									fullWidth
									id="lastName"
									label="Last Name"
									name="lastName"
									autoComplete="family-name"
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									required
									fullWidth
									id="email"
									label="Email Address"
									name="email"
									autoComplete="email"
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									required
									fullWidth
									name="password"
									label="Password"
									type="password"
									id="password"
									autoComplete="new-password"
								/>
							</Grid>
						</Grid>
						<Typography>{errorMessage}</Typography>
						<Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
							Sign Up
						</Button>
						<Grid container justifyContent="flex-end">
							<Grid item>
								<Link href="#" variant="body2">
									Already have an account? Sign in
								</Link>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Container>
		</div>
	)
}

export default Signup
