import React, { useCallback } from 'react'
import { TableCell, TableRow, Checkbox, Box } from '@mui/material'
import ParticipantIcon from '@mui/icons-material/AccountCircle'
import AssignmentIcon from '@mui/icons-material/AssignmentInd'
import CheckIcon from '@mui/icons-material/Check'
import { useNavigate } from 'react-router'
import { useParticipant } from '../../services/hooks/useParticipant'

const AttendanceRow: React.FC<{ id: string; sessionId: string }> = ({ id, sessionId }) => {
	const { participant, toggleAttendance } = useParticipant(id)
	const { lmsId, lastName, firstName, attendance, isSelfVerified } = participant
	const navigate = useNavigate()

	const toVerification = useCallback(() => {
		navigate('/instructorSelfVerification', { state: id })
	}, [navigate, id])

	return (
		<TableRow>
			<TableCell>
				<Box sx={{ alignItems: 'center', display: 'flex' }}>
					<ParticipantIcon
						color="primary"
						fontSize="large"
						sx={{ paddingRight: '10px' }}
					/>
					{lastName}, {firstName}
				</Box>
			</TableCell>
			<TableCell>{lmsId}</TableCell>
			<TableCell onClick={toggleAttendance}>
				<Checkbox checked={attendance} />
			</TableCell>
			<TableCell>
				<Box sx={{ alignItems: 'center', display: 'flex', position: 'relative' }}>
					<AssignmentIcon
						color="primary"
						fontSize="large"
						sx={{ paddingRight: '10px', display: 'block', cursor: 'pointer' }}
						onClick={toVerification}
					/>
					{isSelfVerified && (
						<CheckIcon
							sx={{
								paddingRight: '10px',
								color: 'green',
								fontSize: 50,
								position: 'absolute',
								bottom: 1,
							}}
						/>
					)}
				</Box>
			</TableCell>
		</TableRow>
	)
}

export default AttendanceRow
