import feathers from '@feathersjs/client'
import socketio from '@feathersjs/socketio-client'
import io from 'socket.io-client'
import { Session, Participant, Facility, Class, Attachment, ClassData } from '../types'
import { AppConfig } from '../types/config'
import { removeDays } from '../utils/removeDays'
import { dateObjToDateStrDash } from '../utils/timeConversion'

const serverUrl = process.env.NODE_ENV === 'development' ? 'localhost:3030' : window.location.host
const socket = io(serverUrl || '')
export const server = feathers()

socket.io.on('reconnect_attempt', (attemptCount: number) => {
	if (attemptCount <= 5) return
	if (window.location.pathname !== '/auth/login') window.location.href = '/auth/login'
})

socket.io.on('reconnect', () => {
	if (window.location.pathname === '/auth/login') window.location.href = '/'
})

// server.configure(socketio(socket))
server.configure(
	socketio(socket, {
		timeout: 300000,
	})
)
server.configure(feathers.authentication({ storage: window.localStorage }))

const serverAuthErrorHandler = (error: Error) => {
	console.log('Error is happen for serverAuthErrorHandler', JSON.stringify(error))
	// if (error.name === 'Timeout') window.location.href = '/auth'
	if (error.name === 'NotAuthenticated') {
		console.log(error)
	}
}

export const updateSession = (id: string, value: Session) => {
	if (!id || id === '') return null
	return server.service('sessions').update(id, value).catch(serverAuthErrorHandler)
}

export const patchSession = (
	id: string,
	value: Partial<Session>,
	completionSession = false,
	isMultiDayAttendance?: boolean
) => {
	if (!id || id === '') return null
	return server
		.service('sessions')
		.patch(id, { ...value, isMultiDayAttendance, completionSession })
		.catch(serverAuthErrorHandler)
}
export const patchParticipant = (id: string, value: Partial<Participant>, sessionId: string) => {
	if (!id || id === '') return null
	return server
		.service('participant')
		.patch(id, { value, sessionId })
		.catch(serverAuthErrorHandler)
}
export const addParticipant = (value: Participant, sessionId: string) => {
	if (!sessionId || sessionId === '') return null
	return server
		.service('participant')
		.create({ value, sessionId })
		.then((response: { error: boolean; message: string }) => {
			return response
		})
		.catch(serverAuthErrorHandler)
}

export const getParticipant = (lmsId: string) => {
	if (!lmsId || lmsId === '') return null
	return server.service('participant').get(lmsId).catch(serverAuthErrorHandler)
}

export const updateParticipant = (
	participantId: string,
	value: Partial<Participant>,
	session: Session
): Session | null => {
	const newSession = { ...session }
	if (!session._id) return null
	newSession.participants = newSession.participants.map((participant) => {
		if (participant._id !== participantId) return participant
		return { ...participant, ...value }
	})
	updateSession(session._id, newSession).catch(serverAuthErrorHandler)
	return newSession
}

export const getSessionByLmsId = (lmsId: string, todaysDate: string) => {
	return new Promise<Session>((resolve, reject) => {
		server
			.service('sessions')
			.find({ query: { lmsId: { $regex: `^${lmsId}` } }, date: todaysDate })
			.then((sessions: { data: Session[] }) => {
				if (sessions.data.length === 0) return resolve({} as Session)
				return resolve(sessions.data[0])
			})
			.catch(serverAuthErrorHandler)
			.catch((error: Error) => {
				reject(error)
			})
	})
}
export const getInstructorSessionsById = (instructorId: string) => {
	return new Promise<Session[]>((resolve, reject) => {
		const currentDate = process.env.REACT_APP_DATE || dateObjToDateStrDash()
		const newDate = removeDays(new Date(currentDate), 7)
		server
			.service('sessions')
			.find({
				query: {
					instructorId,
					date: { $gte: dateObjToDateStrDash(newDate), $lte: currentDate },
				},
			})
			.then((sessions: { data: Session[] }) => {
				resolve(sessions.data)
			})
			.catch(serverAuthErrorHandler)
			.catch((error: Error) => {
				reject(error)
			})
	})
}
export const getMultiDayBySessionsId = (sessionId: string) => {
	return new Promise<Session[]>((resolve, reject) => {
		const lmsId = sessionId.split('_')[0]
		server
			.service('sessions')
			.find({ query: { lmsId: { $search: lmsId } }, $limit: 10000 })

			.then((multidaySession: { data: Session[] }) => {
				resolve(multidaySession.data)
			})
			.catch(serverAuthErrorHandler)
			.catch((error: Error) => {
				reject(error)
			})
	})
}

export const createSession = (newSession: Session | undefined) => {
	return new Promise<Session>((resolve, reject) => {
		server
			.service('sessions')
			.create({ ...newSession })
			.then((session: { data: Session }) => {
				resolve(session.data)
			})
			.catch(serverAuthErrorHandler)
			.catch((error: Error) => {
				reject(error)
			})
	})
}

export const getStudentSessionsById = (lmsId: string) => {
	return new Promise<Session[]>((resolve, reject) => {
		const currentDate = process.env.REACT_APP_DATE || dateObjToDateStrDash()
		server
			.service('sessions')
			.find({ query: { 'participants.lmsId': lmsId, date: currentDate }, $limit: 10000 })
			.then((sessions: { data: Session[] }) => {
				resolve(sessions.data)
			})
			.catch(serverAuthErrorHandler)
			.catch((error: Error) => {
				reject(error)
			})
	})
}

export const getAllSessions = () => {
	return new Promise<Session[]>((resolve, reject) => {
		const currentDate = process.env.REACT_APP_DATE || dateObjToDateStrDash()
		const newDate = removeDays(new Date(currentDate), 7)

		server
			.service('sessions')
			.find({
				query: {
					date: { $gte: dateObjToDateStrDash(newDate), $lte: currentDate },
					status: { $ne: 2 },
				},
			})
			.then((sessions: { data: Session[] }) => {
				// const correctSessions = sessions.data.filter(
				// 	(item) => new Date(item.date) > newDate
				// )
				resolve(sessions.data)
			})
			.catch(serverAuthErrorHandler)
			.catch((error: Error) => {
				reject(error)
			})
	})
}

export const getAllFacilities = () => {
	return new Promise<Facility[]>((resolve, reject) => {
		server
			.service('facilities')
			.find({ query: { $limit: 10000 } })
			.then((facilities: { data: Facility[] }) => {
				resolve(facilities.data)
			})
			.catch(serverAuthErrorHandler)
	})
}

export const getClasses = () => {
	return new Promise<Class[]>((resolve, reject) => {
		server
			.service('classes')
			.find({ query: {} })
			.then((response: { data: Class[] }) => {
				resolve(response.data)
			})
			.catch(serverAuthErrorHandler)
			.catch((error: Error) => {
				reject(error)
			})
	})
}

export const emailAttachments = (files: Attachment, classInfo: ClassData, email: string) => {
	return new Promise<string>((resolve, reject) => {
		const mailOptions = {
			contents: classInfo,
			email,
			attachments: [
				{
					filename: 'files-for-now',
					content: files,
				},
			],
		}
		server
			.service('assessment-upload')
			.create(mailOptions)
			.then((response: string) => {
				resolve(response)
			})
			.catch((error: Error) => {
				reject(error)
			})
	})
}

export const getConfigfromServer = () => {
	return new Promise<AppConfig>((resolve, reject) => {
		server
			.service('config')
			.find({ query: { $limit: 100 } })
			.then((response: { data: [AppConfig] }) => {
				const config = response.data[0]
				resolve(config)
			})
			.catch(serverAuthErrorHandler)
			.catch((error: Error) => {
				reject(error)
			})
	})
}

export const getReportsFromServer = () => {
	return new Promise<string[]>((resolve, reject) => {
		server
			.service('reports')
			.get('reports')
			.then((response: string[]) => {
				resolve(response)
			})
			.catch((error: Error) => {
				reject(error)
			})
	})
}

export default server
