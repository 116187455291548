import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { CSVLink } from 'react-csv'

import { userState } from '../services/user'
import { sessionListState, activeSessionState } from '../services/sessions'
import { Session } from '../types'
import { dateObjToDateStrDash } from '../utils/timeConversion'

const AllSessionsReport: React.FC = () => {
	const user = useRecoilValue(userState)
	const sessionList = useRecoilValue(sessionListState)
	const activeSession = useRecoilValue(activeSessionState)
	const [csvData, setCsvData] = useState([['']])

	const generateReport = () => {
		// First column in report
		const headers = [
			'Title',
			'Class ID',
			'Start Time',
			'Actual Start Time',
			'End Time',
			'Actual End Time',
			'Instructor',
			'Actual Instructor',
			'Location',
			'Actual Location',
		]

		const sessionListData = [headers]

		for (const session in sessionList) {
			if (Object.prototype.hasOwnProperty.call(sessionList, session)) {
				const hasError = checkSessionForError(sessionList[session])
				// Add to sessionListData if data doesn't match
				if (hasError) {
					const {
						name,
						lmsId,
						startTime,
						actualStartTime,
						endTime,
						actualEndTime,
						instructorId,
						actualInstructorId,
						location1,
						location2,
						actualLocation1,
						actualLocation2,
					} = sessionList[session]

					const sessionData = [
						name,
						lmsId,
						startTime,
						actualStartTime,
						endTime,
						actualEndTime,
						instructorId,
						actualInstructorId,
						`${location1 === '' ? 'Other' : location1} - ${
							location2 === '' ? 'Other' : location2
						}`,
						`${actualLocation1 === '' ? 'Other' : actualLocation1} - ${
							actualLocation2 === '' ? 'Other' : actualLocation2
						}`,
					]

					sessionListData.push(sessionData)
				}
			}
		}

		setCsvData(sessionListData)
	}

	// Does a check to see if data matches
	const checkSessionForError = (session: Session) => {
		const {
			startTime,
			actualStartTime,
			endTime,
			actualEndTime,
			instructorId,
			actualInstructorId,
			location1,
			actualLocation1,
			location2,
			actualLocation2,
		} = session

		const hasError =
			startTime !== actualStartTime ||
			endTime !== actualEndTime ||
			instructorId !== actualInstructorId ||
			location1 !== actualLocation1 ||
			location2 !== actualLocation2

		return hasError
	}

	useEffect(() => {
		generateReport()
	}, [user, sessionList, activeSession])

	if (user.roles.includes('reporter')) {
		return (
			<CSVLink
				data={csvData}
				filename={`session_report_${dateObjToDateStrDash()}`}
				style={{ textDecoration: 'none' }}
			>
				<Button variant="contained" sx={{ minWidth: '50px' }}>
					REPORT
				</Button>
			</CSVLink>
		)
	}

	return null
}

export default AllSessionsReport
