import React, { useCallback, useState, useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import CheckIcon from '@mui/icons-material/Check'
import { Box, Container, TextField, IconButton } from '@mui/material'
import { useNavigate } from 'react-router'
import { useActiveSession } from '../../services/hooks/useActiveSession'
import { dateYMDdashToMDYslash, militaryToStandard } from '../../utils/timeConversion'
import BottomBar from '../../components/BottomBar'
import InformationBar from '../../components/InformationBar'
import { emailAttachments } from '../../services/server'
import { Attachment } from '../../types'
import { userState } from '../../services/user'

const AttachAssessment: React.FC = () => {
	const navigate = useNavigate()
	const user = useRecoilValue(userState)
	const { activeSession } = useActiveSession()
	const fileInput = React.useRef() as React.MutableRefObject<HTMLInputElement>
	const [attachment, setAttachment] = useState<Attachment | {}>({})
	const [attachmentNames, setAttachmentNames] = useState<string[] | null>(null)
	const classData = {
		name: activeSession.name,
		date: activeSession.date,
		startTime: activeSession.startTime,
		lmsId: activeSession.lmsId,
	}

	useEffect(() => {
		if (attachment) {
			const fileNames = Object.values(attachment).map((file: File) => {
				return file.name
			})
			setAttachmentNames(fileNames)
		}
	}, [attachment])

	const toSubmit = () => {
		emailAttachments(attachment, classData, user.email)
		navigate('/sessionSelection')
	}

	const toSubmissionComplete = useCallback(() => {
		navigate('/submissionComplete')
	}, [navigate])

	const onFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const uploadedFiles: any = {}
		const { files } = e.target
		if (files) {
			for (let i: number = 0; i < files.length; i++) {
				uploadedFiles[files[i].name] = files[i]
			}
		}

		setAttachment({ ...attachment, ...uploadedFiles })
	}

	const getAttachmentsName = () => {
		if (!attachmentNames) return 'Attach Files'
		return `Files (${attachmentNames.length})`
	}

	const boxStyle = { display: 'flex', alignItems: 'center', width: '100%', margin: '0px auto' }
	const labelStyle = { minWidth: '15%' }

	const previewUploads = () => {
		return (
			<div>
				{attachmentNames?.map((item: string) => {
					return (
						<div
							key={item}
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<CheckIcon />
							{item} <br />
						</div>
					)
				})}
			</div>
		)
	}

	return (
		<div style={{ margin: '0px auto' }}>
			<InformationBar currentPage="Attach Assessment" displayClass={true} />
			<Container disableGutters sx={{ padding: '40px' }}>
				<Box sx={boxStyle}>
					<div style={labelStyle}>Title:</div>
					<TextField
						className="fullWidth"
						id="title"
						value={activeSession.name}
						disabled
					/>
				</Box>
				<Box sx={boxStyle}>
					<div style={labelStyle}>Date:</div>
					<TextField
						className="fullWidth"
						id="date"
						value={dateYMDdashToMDYslash(activeSession.date)}
						disabled
					/>
				</Box>
				<Box sx={boxStyle}>
					<div style={labelStyle}>Time:</div>
					<TextField
						className="fullWidth"
						id="time"
						value={militaryToStandard(
							activeSession.actualStartTime ?? activeSession.startTime
						)}
						disabled
					/>
				</Box>
				<h3 style={{ backgroundColor: '#eee', padding: '.5em', fontWeight: 'normal' }}>
					Confirm that you have attached all participant Assessments.
				</h3>
				<Box sx={boxStyle}>
					<div style={labelStyle}>Attachments</div>
					<IconButton
						sx={{ p: '10px' }}
						onClick={() => fileInput?.current?.click()}
						aria-label="attachFile"
					>
						<AttachFileIcon />
					</IconButton>
					<TextField
						className="fullWidth"
						id="attachment"
						value={getAttachmentsName()}
						onClick={() => fileInput?.current?.click()}
						disabled
					/>
					<input
						onChange={(e): void => onFileChange(e)}
						ref={fileInput}
						type="file"
						multiple
						hidden
					/>
				</Box>
				{attachment && previewUploads()}
			</Container>
			<Box>
				<BottomBar
					instructions=""
					buttons={[
						{ label: 'Back', onClick: toSubmissionComplete },
						{ label: 'Submit', onClick: toSubmit },
					]}
				/>
			</Box>
		</div>
	)
}

export default AttachAssessment
