import React, { useEffect, useState } from 'react'
import { TableCell, TableRow } from '@mui/material'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import CircleIcon from '@mui/icons-material/Circle'
import { useNavigate } from 'react-router'
import { sessionState, activeSessionIdState } from '../../services/sessions'
import { SessionStatus } from '../../types'
import {
	dayjsMilitaryToStandard,
	dateObjToDateStrDash,
	formatDateStringToYMD,
} from '../../utils/timeConversion'
import { getMultiDayBySessionsId } from '../../services/server'

interface SessionSelectionRowProps {
	id: string
	userId: string
	userRoles: string[]
}

const SessionSelectionRow: React.FC<SessionSelectionRowProps> = ({ id, userId, userRoles }) => {
	const {
		lmsId,
		name,
		startTime,
		date,
		location1,
		location2,
		status,
		actualInstructorId,
		participants,
	} = useRecoilValue(sessionState(id))

	const setActiveSessionId = useSetRecoilState(activeSessionIdState)
	const navigate = useNavigate()
	const [show, setShow] = useState(false)

	const onSectionClick = () => {
		if (userRoles.includes('instructor')) {
			setActiveSessionId(id)
			if (status === 2) {
				navigate('/submissionComplete')
			} else {
				navigate('/sessionDetails', { state: { origin: 'sessionSelectionRow' } })
			}
		} else {
			navigate('/selfVerification')
		}
	}

	useEffect(() => {
		const currentDate = process.env.REACT_APP_DATE || dateObjToDateStrDash()
		const currentDateYMD = formatDateStringToYMD(currentDate)
		const isCurrentDate = date === currentDateYMD
		const isInstructor = userId === actualInstructorId
		const isParticipant = participants.filter((part) => part.lmsId === userId)[0] || false
		let shouldShow = isCurrentDate

		if (!isCurrentDate) {
			shouldShow = new Date(date) < new Date(currentDateYMD)
		}

		if (lmsId.includes('_')) {
			getMultiDayBySessionsId(lmsId).then((multidaySession) => {
				const latestSession = multidaySession.filter(
					(session) => new Date(session.date) <= new Date(currentDate)
				)
				if (isInstructor) {
					setShow(true)
				} else {
					setShow(false)
				}

				if (
					latestSession[latestSession.length - 1] &&
					latestSession[latestSession.length - 1]._id === id &&
					isInstructor
				) {
					setShow(true)
				} else {
					setShow(false)
				}
			})
		} else if (shouldShow && isInstructor) {
			setShow(true)
		} else {
			setShow(false)
		}
	}, [])
	let styles = {}
	if (lmsId.includes('QR')) {
		styles = {
			color: 'red',
			'font-weight': 'bold',
		}
	}

	if (!show) return null
	return (
		<TableRow key={name} onClick={onSectionClick} sx={{ cursor: 'pointer' }}>
			<TableCell>{name}</TableCell>
			<TableCell style={styles} align="center">
				{lmsId.split('_')[0]}
			</TableCell>
			<TableCell align="center">{startTime && dayjsMilitaryToStandard(startTime)}</TableCell>
			<TableCell align="center">{date}</TableCell>
			<TableCell align="center">{`${location1 === '' ? 'Other' : location1} - ${
				location2 === '' ? 'Other' : location2
			}`}</TableCell>
			<TableCell align="center">
				{status === SessionStatus.COMPLETE && (
					<CircleIcon color="success" fontSize="large" />
				)}
				{status === SessionStatus.NOT_STARTED && (
					<CircleOutlinedIcon color="primary" fontSize="large" />
				)}
				{status === SessionStatus.IN_PROGRESS && (
					<svg width="1em" height="1em" fontSize="2.1875rem" viewBox="0 0 24 24">
						<path
							d="M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2m0 2a8 8 0 0 1 8 8a8 8 0 0 1-8 8V4z"
							fill="#FDDA0D"
						/>
					</svg>
				)}
			</TableCell>
		</TableRow>
	)
}

export default SessionSelectionRow
