import React from 'react'
import { Route, Routes } from 'react-router'
import { Container } from '@mui/material'
import Login from './Login'
import Signup from './Signup'
import ResetPasswordRequest from './ResetPasswordRequest'
import ResetPassword from './ResetPassword'
import Reauthenticate from './Reauthenticate'

const AuthRoutes: React.FC<{ className?: string }> = ({ className }) => {
	return (
		<Container>
			<Routes>
				<Route path="/" element={<Reauthenticate />} />
				<Route path="/login" element={<Login />} />
				<Route path="/signup" element={<Signup />} />
				<Route path="/resetPasswordRequest" element={<ResetPasswordRequest />} />
				<Route path="/resetPassword/:token" element={<ResetPassword />} />
			</Routes>
		</Container>
	)
}

export default AuthRoutes
