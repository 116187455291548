export enum SessionStatus {
	NOT_STARTED = 0,
	IN_PROGRESS = 1,
	COMPLETE = 2,
}

export enum CompletionStatus {
	NOT_SET = 0,
	INCOMPLETE = 1,
	COMPLETE = 2,
}

export interface Session {
	_id?: string
	lmsId: string
	sessionTemplateId: string
	name: string
	instructorId: string
	startTime: string
	endTime: string
	date: string
	location1: string
	location2: string
	status: SessionStatus
	participants: Participant[]
	attachement?: string
	comments?: string
	time?: string
	multiday?: Boolean
	notes: string
	actualInstructorId?: string
	actualStartTime?: string
	actualEndTime?: string
	actualLocation1?: string
	actualLocation2?: string
}

export interface Participant {
	_id: string
	lmsId: string
	firstName: string
	lastName: string
	attendance: boolean
	completion: CompletionStatus
	isSelfVerified: boolean
	selfVerifiedDate: string
	whoVerified: string
	walkIn?: boolean
}

export interface Room {
	_id: string
	lmsId: string
	name: string
}

export interface Facility {
	_id: string
	facilityId: string
	facilityName: string
	rooms: Room[]
}
