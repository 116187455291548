import { Box } from '@mui/material'
import React from 'react'
import { useActiveSession } from '../services/hooks/useActiveSession'

const InformationBar: React.FC<{ currentPage: string; displayClass: Boolean }> = ({
	currentPage,
	displayClass,
}) => {
	const { activeSession } = useActiveSession()

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				bgcolor: 'gray',
				fontWeight: '650',
				lineHeight: '3',
				fontSize: '1rem',
			}}
		>
			<Box sx={{ ml: '30px' }}>{currentPage}</Box>
			{displayClass && (
				<Box
					sx={{ mr: '30px' }}
				>{`${activeSession.name} - Class ID: ${activeSession.lmsId}`}</Box>
			)}
		</Box>
	)
}

export default InformationBar
