import React from 'react'
import { TableCell, TableRow, Box } from '@mui/material'
import ParticipantIcon from '@mui/icons-material/AccountCircle'
import { useParticipant } from '../../services/hooks/useParticipant'
import { CompletionStatus } from '../../types/sessions'

const SummaryReviewRow: React.FC<{ id: string }> = ({ id }) => {
	const { participant } = useParticipant(id)
	const { lmsId, lastName, firstName, completion, attendance, isSelfVerified } = participant

	return (
		<TableRow>
			<TableCell>
				<Box sx={{ alignItems: 'center', display: 'flex' }}>
					<ParticipantIcon
						color="primary"
						fontSize="large"
						sx={{ paddingRight: '10px' }}
					/>
					{lastName}, {firstName}
				</Box>
			</TableCell>
			<TableCell align="center">{lmsId}</TableCell>
			<TableCell align="center" sx={{ textTransform: 'capitalize' }}>
				{attendance && isSelfVerified
					? CompletionStatus[completion].toLowerCase()
					: 'No Show'}
			</TableCell>
		</TableRow>
	)
}

export default SummaryReviewRow
