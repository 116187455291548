import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import {
	Alert,
	Avatar,
	Box,
	Button,
	Container,
	CssBaseline,
	TextField,
	Typography,
} from '@mui/material'
import { useNavigate, useParams } from 'react-router'
import { authState, AUTH_STATUS, usePasswordReset } from '../../services/authentication'

const ResetPassword: React.FC<{ className?: string }> = ({ className }) => {
	const auth = useRecoilValue(authState)
	const [errorMessage, setErrorMessage] = useState('')
	const resetPassword = usePasswordReset()
	const navigate = useNavigate()
	const params = useParams()

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		const password = data.get('password')?.toString() || ''

		if (password.length < 3) {
			setErrorMessage('Invalid Password.')
			return
		}

		if (auth.status === AUTH_STATUS.PASSWORD_RESET_FAILED)
			setErrorMessage(`Failed to reset password. Please try again.`)
		else setErrorMessage('')

		if (!params.token) return

		resetPassword(password, params.token)
	}

	useEffect(() => {
		if (auth.status === AUTH_STATUS.PASSWORD_RESET_FAILED)
			setErrorMessage(`Failed to reset password. Please try again.`)
		else setErrorMessage('')
	}, [auth.status, navigate])

	return (
		<div data-test="ResetPassword" className={`ResetPassword ${className || ''}`}>
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<Box
					sx={{
						marginTop: 8,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }} />
					<Typography component="h1" variant="h5">
						Reset Password
					</Typography>

					<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
						<TextField
							margin="normal"
							required
							fullWidth
							name="password"
							label="New Password"
							type="password"
							id="password"
							autoComplete="current-password"
						/>
						{errorMessage.length > 0 && <Alert severity="error">{errorMessage}</Alert>}{' '}
						<Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
							Reset Password
						</Button>
					</Box>
				</Box>
			</Container>
		</div>
	)
}

export default ResetPassword
