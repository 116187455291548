import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { Box, Button } from '@mui/material'
import { useParticipant } from '../../services/hooks/useParticipant'
import Modal from '../../components/Modal'

const HandoffVerificationConfirmation: React.FC<{
	employeeData: {
		_id: string
		firstName: string
		lastName: string
		className: string
		classTime: string
	}
}> = ({ employeeData }) => {
	const navigate = useNavigate()

	const { toggleAttendance } = useParticipant(employeeData._id)
	const [displayModal, setDisplayModal] = useState<boolean>(false)

	const confirmAttendance = () => {
		// TODO:// Decide if we need to check if they are signed in already
		toggleAttendance()
		setDisplayModal(true)
	}

	const closeModal = () => {
		setDisplayModal(false)
		navigate('/')
	}

	return (
		<>
			{displayModal && (
				<Modal title="Sucess" message="Attendance was Updated" updateParent={closeModal} />
			)}
			<Box
				id="input-box"
				sx={{
					margin: '10px 0px',
				}}
			>
				<Box component="label">FirstName:</Box>

				<Box id="display-box">{employeeData.firstName}</Box>
			</Box>

			<Box id="input-box">
				<Box component="label">Last Name:</Box>

				<Box id="display-box">{employeeData.lastName}</Box>
			</Box>

			<Box sx={{ pb: '10px' }}>
				<Box
					id="input-box"
					sx={{
						justifyContent: 'center',
					}}
				>
					<h4>{employeeData.className}</h4> - {employeeData.classTime}
				</Box>

				<Box id="button">
					<Button
						variant="contained"
						color="error"
						onClick={confirmAttendance}
						sx={{ minWidth: '200px' }}
					>
						Confirm Attendance
					</Button>
				</Box>
			</Box>

			<Box sx={{ margin: '3% 3% 0' }}>
				By selecting "Confirm Attendance" above, you acknowledge that you are the person
				named above, and that you were in attendance for the specified class session
			</Box>
		</>
	)
}

export default HandoffVerificationConfirmation
