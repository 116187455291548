import React, { useState, useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Container,
	Box,
	TextField,
	Button,
} from '@mui/material'
import { useNavigate } from 'react-router'
import { userState } from '../../services/user'
import BottomBar from '../../components/BottomBar'
import SelfVerificationRow from './SelfVerificationRow'
import InformationBar from '../../components/InformationBar'
import Modal from '../../components/Modal'
import { sessionListState } from '../../services/sessions'
import { useGetStudentSessions } from '../../services/hooks/useSessionsFromServer'
import {
	addParticipant,
	getMultiDayBySessionsId,
	getSessionByLmsId,
	server,
} from '../../services/server'
import { authState, AUTH_STATUS } from '../../services/authentication'
import { Participant } from '../../types'
import { dateStringToDateObj, dateObjToDateStrDash } from '../../utils/timeConversion'

const SelfVerification = () => {
	const auth = useRecoilValue(authState)
	const user = useRecoilValue(userState)
	const getSessionsFromServer = useGetStudentSessions(user.lmsId)

	const sessions = useRecoilValue(sessionListState)
	const navigate = useNavigate()
	const [classId, setClassId] = useState('')
	const [displayModal, setDisplayModal] = useState('')
	const [errorList, setErrorList] = useState<{ lmsId: string; errorMessage: string }[]>([])

	useEffect(() => {
		if (auth.status !== AUTH_STATUS.AUTHENTICATED) return
		getSessionsFromServer()
	}, [auth.status, getSessionsFromServer])

	useEffect(() => {
		server.service('sessions').on('updated', getSessionsFromServer)
		server.service('sessions').on('patched', getSessionsFromServer)
		return () => {
			server.service('sessions').off('updated', getSessionsFromServer)
			server.service('sessions').off('patched', getSessionsFromServer)
		}
	})

	useEffect(() => {
		if (errorList.length > 0) {
			setDisplayModal(`Error adding users in:`)
		}
	}, [errorList])

	const addMultidayClass = async (walkIn: Participant, lmsId: string) => {
		const multidaySessions = await getMultiDayBySessionsId(lmsId)

		multidaySessions.map((session) => {
			if (!session._id) return null
			return addParticipant(walkIn, session._id).then(
				(data: { error: boolean; message: string | { message: string } }) => {
					if (data.error) {
						setErrorList((prevError) => {
							return [
								...prevError,
								{
									lmsId: session.lmsId,
									errorMessage:
										typeof data.message === 'string'
											? data.message
											: data.message.message,
								},
							]
						})
					}
					return walkIn
				}
			)
		})
	}

	const addClass = async () => {
		if (classId.trim() === '') return setDisplayModal('Please enter a class ID')
		const todaysDate = dateObjToDateStrDash()
		// create walking data
		const walkIn: Participant = {
			firstName: user.firstName,
			lastName: user.lastName,
			lmsId: user.lmsId,
			completion: 0,
			attendance: false,
			isSelfVerified: false,
			selfVerifiedDate: '',
			whoVerified: '',
			_id: '',
		}
		// add to session
		const session = await getSessionByLmsId(classId, todaysDate)
		if (!session || !session._id) return setDisplayModal('Class not found for todays date')
		if (session.date !== todaysDate) {
			return setDisplayModal(
				`Unable to add to class that is not today. Session date: ${session.date}`
			)
		}

		if (
			session.participants.filter((participant) => participant.lmsId === walkIn.lmsId)
				.length > 0
		) {
			setDisplayModal('You are already enrolled in class')
			return setClassId('')
		}

		if (session.lmsId.includes('_')) {
			addMultidayClass(walkIn, session.lmsId)
			return setClassId('')
		}
		addParticipant(walkIn, session._id).then((data: { error: boolean; message: string }) => {
			if (data.error) {
				return setDisplayModal(data.message)
			}
			return null
		})
		return setClassId('')

		// if multiday session, add to all sessions
	}

	const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setClassId(event.currentTarget.value.toString())
	}

	const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
		if (e.key === 'Enter') {
			addClass()
		}
	}

	const closeModal = () => {
		setDisplayModal('')
		setErrorList([])
	}

	const onBack = () => {
		navigate('/sessionSelection')
	}
	// TODO: need a better way to handle the table so it can be responsive to modile device sizes. right now just doing transform scale in index.css to get it to fit on screen which is not ideal.
	return (
		<Container disableGutters>
			{displayModal !== '' && (
				<Modal
					title="Error"
					message={displayModal}
					updateParent={closeModal}
					errorList={errorList}
				/>
			)}
			<InformationBar currentPage="Confirm Attendance" displayClass={false} />
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					lineHeight: '3',
					fontSize: '1rem',
					bgcolor: 'white',
				}}
			>
				<Box sx={{ ml: '30px' }}>User ID: {user.lmsId}</Box>
				<Box sx={{ mr: '30px' }}>First Name: {user.firstName}</Box>
				<Box sx={{ mr: '30px' }}>Last Name: {user.lastName}</Box>
			</Box>
			<Box
				sx={{
					bgcolor: '#eee',
					textAlign: 'center',
					lineHeight: '2',
					width: '100%',
					maxWidth: '1200px',
					zIndex: '1101',
				}}
			>
				Confirm Attendance for each class you are attending
			</Box>
			<Table className="verificationList">
				<TableHead>
					<TableRow>
						<TableCell>Title</TableCell>
						<TableCell align="center">Class ID</TableCell>
						<TableCell align="center">Time</TableCell>
						<TableCell align="center">Date</TableCell>
						<TableCell align="center">Attendance</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{sessions.map((session) => {
						if (dateStringToDateObj(session.date).getDate() === new Date().getDate()) {
							return (
								<SelfVerificationRow
									key={session._id}
									session={session}
									user={user}
								/>
							)
						}
						return null
					})}
				</TableBody>
			</Table>
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						width: '25%',
						padding: '2%',
					}}
				>
					<div style={{ minWidth: '40%' }}>Enter Class ID:</div>
					<TextField
						sx={{ height: '50%' }}
						id="enterClass"
						value={classId}
						onChange={onInputChange}
						onKeyDown={onKeyDown}
					/>
				</Box>
				<Button variant="contained" type="button" onClick={addClass}>
					Add Class
				</Button>
			</div>
			<Box
				className="notificationText"
				sx={{
					bgcolor: '#eee',
					textAlign: 'center',
					lineHeight: '2',
					position: 'fixed',
					bottom: '95px',
					width: '100%',
					maxWidth: '1200px',
					zIndex: '1101',
				}}
			>
				By selecting "Confirm Attendance" above, you acknowledge that you are the person
				named above, and that you were in attendance for the specified class session.
			</Box>
			<BottomBar
				instructions=""
				buttons={
					user.roles.indexOf('instructor') >= 0
						? [{ label: 'Instructor View', onClick: onBack }]
						: []
				}
			/>
		</Container>
	)
}

export default SelfVerification
