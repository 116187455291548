import { useEffect, useState } from 'react'
import { Class } from '../../types'
import { getClasses } from '../server'

export const useClasses = () => {
	const [classes, setClasses] = useState<Class[]>([])

	useEffect(() => {
		getClasses().then((serverClasses) => {
			setClasses(serverClasses)
		})
	}, [])

	return classes
}
