import React, { useEffect, useState } from 'react'
import { Box, TextField } from '@mui/material'
import { getAllFacilities } from '../../services/server'

import SearchAutoComplete from '../../components/SearchAutoComplete'
import { Facility, Room } from '../../types'
import { useGetConfig } from '../../services/hooks/useConfig'

interface LocationProps {
	location1: string
	location2: string
	setLocation1: Function
	setLocation2: Function
	summary: boolean
	locked?: boolean
}

const Locations: React.FC<LocationProps> = ({
	location1,
	location2,
	setLocation1,
	setLocation2,
	summary,
	locked,
}) => {
	const [facilities, setFacilities] = useState<Facility[]>([])
	const [facilityNames, setFacilityNames] = useState<string[] | []>([])
	const [roomNames, setRooms] = useState<string[] | []>([])
	const getConfig = useGetConfig()

	useEffect(() => {
		const fetchFacilities = async () => {
			const allFacilities = await getAllFacilities()
			setFacilities(allFacilities)
			const facilityIdList = allFacilities.map((facility: Facility) => {
				return facility.facilityName
			})

			setFacilityNames(facilityIdList.sort())
		}
		fetchFacilities()
	}, [])

	useEffect(() => {
		const currentFacility = facilities.filter((facility: Facility) => {
			return facility.facilityName === location1
		})[0]
		if (currentFacility?.rooms) {
			const roomList = currentFacility.rooms.map((room: Room) => {
				return room.name
			})

			setRooms(roomList)
		}
	}, [facilities, location1])

	const onFacilitySelection = (facilityName: string) => {
		if (facilityName === '') {
			setLocation1('')
			setLocation2('')
			const roomList = []
			setRooms([])
		} else if (facilityName === null) {
			setRooms([])
		} else {
			const facil = facilities.filter((facility: Facility) => {
				/*
					for some weird reason the MaterialUI element returns
					up to the '-' but nothing after it
					could casuse severe issues
				*/
				return facility.facilityName === facilityName
			})[0]
			setLocation1(facil.facilityName)

			const roomList = facil.rooms.map((room: Room) => {
				return room.name
			})

			setRooms(roomList)
			setLocation2(roomList[0])
		}
	}

	const onRoomSelection = (roomName: string) => {
		if (roomName === '') {
			setLocation2('')
		} else {
			setLocation2(roomName)
		}
	}

	const getFacilityName = (locId: string): string => {
		if (facilities.length < 1) {
			return locId
		}
		const data = facilities.filter((fac) => fac.facilityId === locId)
		if (data.length < 1) return locId
		return data[0].facilityName
	}

	if (locked || getConfig?.canEditSession) {
		return (
			<Box sx={{ margin: '10px', display: 'flex', width: '100%' }}>
				<TextField
					className="fullWidth"
					label="Facility"
					id="Facility Search Bar"
					value={getFacilityName(location1)}
					sx={{ margin: '0px', marginRight: '10px' }}
					disabled
				/>
				<TextField
					className="fullWidth"
					label="Location"
					id="Room Search Bar"
					value={location2}
					sx={{ margin: '0px' }}
					disabled
				/>
			</Box>
		)
	}
	return (
		<Box
			sx={{
				margin: '10px',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				width: '100%',
			}}
		>
			<SearchAutoComplete
				suggestions={facilityNames}
				updateSelectionToPartner={onFacilitySelection}
				defaultValue={getFacilityName(location1)}
				label={summary ? 'Actual Facility' : ' Facility'}
				id="Facility Search Bar"
				fromLocation={true}
			/>
			<SearchAutoComplete
				suggestions={roomNames}
				updateSelectionToPartner={onRoomSelection}
				defaultValue={location2}
				label={summary ? 'Actual Location' : ' Location'}
				id="Room Search Bar"
				fromLocation={true}
			/>
		</Box>
	)
}

export default Locations
