import React, { useCallback, useState, useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { useNavigate } from 'react-router'
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Container,
	Box,
	TextField,
} from '@mui/material'
import InformationBar from '../../components/InformationBar'
import BottomBar from '../../components/BottomBar'
import { participantIdsState, sessionListState } from '../../services/sessions'
import CompletionRecordRow from './CompletionRecordRow'
import { useActiveSession } from '../../services/hooks/useActiveSession'
import { militaryToStandard } from '../../utils/timeConversion'
import { Session } from '../../types'
import { useUpdateMultidaySessionsToServer } from '../../services/hooks/useSessionsFromServer'
import InstructorNotes from '../../components/InstructorNotes'

const CompletionRecording = () => {
	const navigate = useNavigate()
	const participantIds = useRecoilValue(participantIdsState)
	const { activeSession, updateActiveSession } = useActiveSession()
	const { lmsId, startTime } = activeSession
	const sessionList = useRecoilValue(sessionListState)
	const [endTime, setEndTime] = useState(activeSession.endTime)
	const [sessionsToUpdate, setSessionsToUpdate] = useState<Session[]>([])
	const [attendanceList, setAttendanceList] = useState<{ [key: string]: number }>({})
	const updateSession = useUpdateMultidaySessionsToServer(sessionsToUpdate)

	useEffect(() => {
		if (sessionsToUpdate?.length) {
			updateSession()
			setSessionsToUpdate([])
		}
	}, [sessionsToUpdate, updateSession])

	useEffect(() => {
		if (lmsId.includes('_')) {
			const list: { [key: string]: number } = {}
			for (let i = 0; i < sessionList.length; i++) {
				for (let j = 0; j < sessionList[i].participants.length; j++) {
					const targetParticipant = sessionList[i].participants[j]
					// initialize
					if (!list[targetParticipant.lmsId]) {
						list[targetParticipant.lmsId] = 0
					}
					// increment
					if (targetParticipant.attendance && targetParticipant.isSelfVerified) {
						list[targetParticipant.lmsId] += 1
					}
				}
			}
			setAttendanceList(list)
		}
	}, [lmsId, sessionList])

	const onTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEndTime(event.target.value)
	}

	const onLeaveField = () => {
		if (endTime === activeSession.endTime) return
		updateActiveSession({ endTime })
	}

	const toAttendance = useCallback(() => {
		if (lmsId.includes('_')) updateSession()
		navigate('/attendance')
	}, [lmsId, navigate, updateSession])

	const toSummary = useCallback(() => {
		if (lmsId.includes('_')) updateSession()
		navigate('/summary')
	}, [lmsId, updateSession, navigate])

	const determineButtons = () => {
		// Checking if there is a user who has not been set
		const notSetUser = activeSession.participants.filter(
			(participant) => participant.attendance && participant.completion === 0
		)[0]

		if (notSetUser) {
			return [
				{ label: 'Attendance', onClick: toAttendance },
				{ label: 'Review', onClick: toSummary, disabled: true },
			]
		}
		return [
			{ label: 'Attendance', onClick: toAttendance },
			{ label: 'Review', onClick: toSummary },
		]
	}
	return (
		<Container disableGutters>
			<InformationBar currentPage="Completion Status" displayClass={true} />
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Participant</TableCell>
						{lmsId.includes('_') && <TableCell align="center">Attendance</TableCell>}
						<TableCell align="center">User ID</TableCell>
						<TableCell align="center">Complete</TableCell>
						<TableCell align="center">Incomplete</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{participantIds.map((id) => {
						return (
							<CompletionRecordRow
								key={id}
								id={id}
								attendanceCount={attendanceList}
								maxSessions={sessionList.length}
								isMultiday={!!lmsId.includes('_')}
							/>
						)
					})}
				</TableBody>
			</Table>
			<InstructorNotes
				sessionsToUpdate={sessionsToUpdate}
				setSessionsToUpdate={setSessionsToUpdate}
			/>
			<BottomBar instructions="" buttons={determineButtons()} />
		</Container>
	)
}

export default CompletionRecording
