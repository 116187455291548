import { useEffect, useState } from 'react'
import { AppConfig } from '../../types/config'
import { getConfigfromServer } from '../server'

export const useGetConfig = () => {
	const [config, setConfig] = useState<AppConfig | null>(null)

	useEffect(() => {
		getConfigfromServer().then((configData) => {
			setConfig(configData)
		})
	}, [])

	return config
}
