import { useCallback, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { atom, useRecoilState } from 'recoil'
import { server } from './server'
import { userState } from './user'

export const AUTH_STATUS = {
	CHECKING_REAUTHORIZATION: 'checking_reauthorization',
	AUTHENTICATED: 'authenticated',
	REQUIRES_LOGON: 'requires_login',
	AUTHENTICATION_FAILED: 'authentication_failed',
	NEW_ACCOUNT: 'new_account',
	PASSWORD_RESET_FAILED: 'password_reset_failed',
	PASSWORD_RESET: 'password_reset',
	PASSWORD_RESET_REQUESTED: 'password_reset_requested',
} as const

interface AuthStateType {
	userId: string
	status: string
}

const defaultAuthState: AuthStateType = {
	userId: '',
	status: AUTH_STATUS.CHECKING_REAUTHORIZATION,
}
export const authState = atom({
	key: 'auth',
	default: defaultAuthState,
})

export const useForceAuthentication = () => {
	const [auth] = useRecoilState(authState)
	const navigate = useNavigate()
	const location = useLocation()

	// go to auth if user is not authenticated
	useEffect(() => {
		if (auth.status !== AUTH_STATUS.AUTHENTICATED && location.pathname.indexOf('/auth') !== 0) {
			console.log('User is not longer auth in authentication.ts')
			navigate('/auth')
		}
	}, [auth.status, navigate, location.pathname])
}

export const useAuthenticator = () => {
	const [auth, setAuth] = useRecoilState(authState)
	const [, setUser] = useRecoilState(userState)
	const navigate = useNavigate()

	const authenticateUser = useCallback(
		({ email, password }) => {
			const authenticationRequest = { email, password, strategy: 'local' }
			server
				.authenticate(authenticationRequest)
				.then(({ user }) => {
					setAuth({
						...auth,
						userId: user._id,
						status: AUTH_STATUS.AUTHENTICATED,
					})
					setUser({ ...user })
				})
				.catch((e) => {
					setAuth({ ...auth, status: AUTH_STATUS.AUTHENTICATION_FAILED })
				})

			const redirectToLogin = () => {
				navigate('/auth/login')
			}

			server.on('reauthentication-error', redirectToLogin)

			return () => {
				server.off('reauthentication-error', redirectToLogin)
			}
		},
		[auth, setAuth, setUser, navigate]
	)

	return authenticateUser
}

interface NewUserData {
	firstName: string
	lastName: string
	email: string
	password: string
}

export const useAccountCreation = () => {
	const [auth, setAuth] = useRecoilState(authState)

	const createAccount = (userData: NewUserData) => {
		server
			.service('users')
			.create(userData)
			.then(() => {
				setAuth({ ...auth, status: AUTH_STATUS.NEW_ACCOUNT })
			})
	}
	return createAccount
}

export const usePasswordResetRequest = () => {
	const [auth, setAuth] = useRecoilState(authState)
	const resetPasswordRequest = useCallback(
		(email: string) => {
			server
				.service('authManagement')
				.create({
					action: 'sendResetPwd',
					value: {
						email,
					},
				})
				.then(() => {
					setAuth({ ...auth, status: AUTH_STATUS.PASSWORD_RESET_REQUESTED })
				})
				.catch(() => {
					setAuth({ ...auth, status: AUTH_STATUS.PASSWORD_RESET_FAILED })
				})
		},
		[auth, setAuth]
	)

	return resetPasswordRequest
}

export const usePasswordReset = () => {
	const [auth, setAuth] = useRecoilState(authState)
	const resetPassword = useCallback(
		(password: string, token: string) => {
			server
				.service('authManagement')
				.create({
					action: 'resetPwdLong',
					value: {
						token,
						password,
					},
				})
				.then(() => {
					setAuth({ ...auth, status: AUTH_STATUS.PASSWORD_RESET })
				})
				.catch(() => {
					setAuth({ ...auth, status: AUTH_STATUS.PASSWORD_RESET_FAILED })
				})
		},
		[auth, setAuth]
	)

	return resetPassword
}
