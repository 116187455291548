import { Box, Container, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { useNavigate } from 'react-router'
import dayjs from 'dayjs'
import InformationBar from '../../components/InformationBar'
import BottomBar from '../../components/BottomBar'
import MultidayDropDown from '../../components/MultidayDropDown'
import { useActiveSession } from '../../services/hooks/useActiveSession'
import { sessionListState } from '../../services/sessions'
import Locations from '../SessionDetails/Location'
import { Session } from '../../types'
import { patchSession } from '../../services/server'
import Modal from '../../components/Modal'

const SessionSummary = () => {
	const navigate = useNavigate()
	const { activeSession, updateActiveSession } = useActiveSession()
	const {
		name,
		date,
		lmsId,
		sessionTemplateId,
		notes,
		actualLocation1,
		actualLocation2,
		actualInstructorId,
	} = activeSession
	const [startTime, setStartTime] = useState('')
	const [ET, setEndTime] = useState('')
	const [location1, setLocation1] = useState(actualLocation1 || '')
	const [location2, setLocation2] = useState(actualLocation2 || '')
	const sessionList = useRecoilValue(sessionListState)
	const [selectedSession, setSelectedSession] = useState<string>(lmsId)
	const [displayModal, setDisplayModal] = useState<boolean>(false)

	useEffect(() => {
		updateActiveSession({ status: 1 })
	}, [updateActiveSession])

	useEffect(() => {
		const st =
			activeSession.startTime.length === 4
				? `0${activeSession.startTime}`
				: activeSession.startTime
		setStartTime(st)
	}, [activeSession.startTime])

	useEffect(() => {
		const et =
			activeSession.endTime.length === 4 ? `0${activeSession.endTime}` : activeSession.endTime
		setEndTime(et)
	}, [activeSession.endTime])

	const onLeaveField = () => {
		updateActiveSession({
			actualStartTime: startTime,
			actualEndTime: ET,
			actualLocation1: location1,
			actualLocation2: location2,
		})
	}

	const onTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.target.id === 'startTime'
			? setStartTime(event.target.value)
			: setEndTime(event.target.value)
	}

	const toSessionSelection = () => {
		navigate('/summary')
	}

	const toSubmissionComplete = () => {
		// If the current time is before the start time, display an error modal
		if (dayjs().format('YYYY-MM-DD HH:mm:ss') < `${date} ${startTime}`) {
			return setDisplayModal(true)
		}
		if (activeSession.lmsId.includes('_')) {
			console.log('Multi-day session', sessionList)
			sessionList.forEach((session: Session, index: number) => {
				if (!session._id) return

				const isLast = index === sessionList.length - 1

				patchSession(session._id, { status: 2 }, isLast)
			})
			return navigate('/submissionComplete')
		}
		if (!activeSession._id) return null
		patchSession(activeSession._id, { status: 2 }, true)
		return navigate('/submissionComplete')
	}

	const closeModal = () => {
		setDisplayModal(false)
	}

	return (
		<Container disableGutters onBlur={onLeaveField}>
			{displayModal && (
				<Modal
					title="Error"
					message="Unable to Submit Session Prior to Start Time"
					updateParent={closeModal}
				/>
			)}
			<InformationBar currentPage="Session Summary" displayClass={true} />
			{lmsId.includes('_') && (
				<MultidayDropDown
					selectedSession={selectedSession}
					setSelectedSession={setSelectedSession}
					currentPage="SessionSummmary"
				/>
			)}
			<Box sx={{ padding: '40px' }}>
				<TextField
					className="fullWidth"
					id="item-id"
					label="Item ID"
					value={sessionTemplateId}
					disabled
				/>
				<TextField
					className="fullWidth"
					id="session-name"
					label="Class Title"
					value={name}
					disabled
				/>
				<TextField
					className="fullWidth"
					id="instructor"
					label="InstructorID"
					value={actualInstructorId}
					disabled
				/>
				<TextField
					className="fullWidth"
					id="date"
					label="Date"
					type="date"
					value={date}
					disabled
				/>
				<Box sx={{ margin: '10px', display: 'flex', width: '100%' }}>
					<TextField
						className="fullWidth"
						id="startTime"
						label="Actual Start Time"
						type="time"
						value={startTime}
						onChange={onTimeChange}
						sx={{ margin: '0px', marginRight: '10px' }}
					/>
					<TextField
						className="fullWidth"
						id="endTime"
						label="Actual End Time"
						type="time"
						onChange={onTimeChange}
						value={ET || startTime}
						sx={{ margin: '0px' }}
					/>
				</Box>
				<TextField
					className="fullWidth"
					id="session-id"
					label="Class Id"
					value={lmsId}
					disabled
				/>
				<Locations
					location1={location1}
					location2={location2}
					setLocation1={setLocation1}
					setLocation2={setLocation2}
					summary={true}
				/>

				{!lmsId.includes('_') && (
					<TextField
						className="fullWidth"
						id="instructor-notes"
						label="Instructor Notes"
						value={
							'notes' in activeSession && notes !== '' ? notes : 'No Instructor Notes'
						}
						disabled
					/>
				)}
			</Box>

			<Box
				sx={{
					bgcolor: '#eee',
					textAlign: 'center',
					lineHeight: '2',
					position: 'fixed',
					bottom: '95px',
					width: '100%',
					maxWidth: '1200px',
					zIndex: '1101',
				}}
			>
				Please review this summary to make sure all the information is correct.
			</Box>
			<BottomBar
				instructions=""
				buttons={[
					{ label: 'Back', onClick: toSessionSelection },
					{ label: 'Submit', onClick: toSubmissionComplete },
				]}
			/>
		</Container>
	)
}

export default SessionSummary
