import React from 'react'
import { TableCell, TableRow, Checkbox, Box } from '@mui/material'
import ParticipantIcon from '@mui/icons-material/AccountCircle'
import { useParticipant } from '../../services/hooks/useParticipant'
import { CompletionStatus } from '../../types/sessions'

const CompletionRecordRow: React.FC<{
	id: string
	attendanceCount: { [key: string]: number }
	maxSessions: number
	isMultiday: boolean
}> = ({ id, attendanceCount, maxSessions, isMultiday }) => {
	const { participant, updateParticipant } = useParticipant(id)
	const { lmsId, lastName, firstName, completion, attendance, isSelfVerified } = participant
	const setCompletion = () => {
		let newCompletionStatus = CompletionStatus.COMPLETE

		if (completion === CompletionStatus.COMPLETE) {
			newCompletionStatus = CompletionStatus.INCOMPLETE
		}
		updateParticipant({ completion: newCompletionStatus })
	}
	const setIncompletion = () => {
		let newCompletionStatus = CompletionStatus.INCOMPLETE

		if (completion === CompletionStatus.INCOMPLETE) {
			newCompletionStatus = CompletionStatus.COMPLETE
		}
		updateParticipant({ completion: newCompletionStatus })
	}
	if (
		(isMultiday && attendanceCount[lmsId] <= 0) ||
		(!isMultiday && (!attendance || !isSelfVerified))
	)
		return null
	return (
		<TableRow>
			<TableCell>
				<Box sx={{ alignItems: 'center', display: 'flex' }}>
					<ParticipantIcon
						color="primary"
						fontSize="large"
						sx={{ paddingRight: '10px' }}
					/>
					{lastName}, {firstName}
				</Box>
			</TableCell>
			{isMultiday && (
				<TableCell align="center" onClick={setIncompletion}>
					{`${attendanceCount[lmsId]}/${maxSessions}`}
				</TableCell>
			)}
			<TableCell align="center">{lmsId}</TableCell>
			<TableCell align="center" onClick={setCompletion}>
				<Checkbox checked={completion === CompletionStatus.COMPLETE} />
			</TableCell>
			<TableCell align="center" onClick={setIncompletion}>
				<Checkbox checked={completion === CompletionStatus.INCOMPLETE} />
			</TableCell>
		</TableRow>
	)
}

export default CompletionRecordRow
