import React, { useEffect, useState } from 'react'
import { Container, Box, Chip, Button, Grid, Divider, Stack, Typography } from '@mui/material'
import { getReportsFromServer } from '../../services/server'
import AllSessionsReport from '../../components/AllSessionsReport'

const Reports = () => {
	const [filesNames, setFileNames] = useState<string[]>([])
	useEffect(() => {
		getReportsFromServer().then((files: string[]) => {
			setFileNames(files)
		})
	}, [])
	return (
		<Container disableGutters>
			<Box
				sx={{ width: '100%', maxWidth: 480, bgcolor: 'background.paper', margin: '0 auto' }}
			>
				<Box sx={{ my: 3, mx: 2 }}>
					<Grid container alignItems="center">
						<Grid item xs>
							<Typography gutterBottom variant="h6" component="div" sx={{ mb: 2 }}>
								Generate Session Report
							</Typography>
						</Grid>
					</Grid>
					<Box sx={{ mt: 3, ml: 1, mb: 1, margin: '0 auto' }}>
						<AllSessionsReport />
					</Box>
				</Box>
				<Divider variant="middle" sx={{ borderBottomWidth: 5 }} />
				<Box sx={{ my: 3, mx: 2 }}>
					<Grid container alignItems="center">
						<Grid item xs>
							<Typography gutterBottom variant="h6" component="div" sx={{ mb: 2 }}>
								ScheduleOfferingConnector Files
							</Typography>
						</Grid>
					</Grid>
					<Box sx={{ maxHeight: 560, overflow: 'auto' }}>
						{filesNames &&
							filesNames.map((file) => {
								return (
									<Box key={file}>
										<Typography
											color="text.secondary"
											variant="body2"
											sx={{ color: file.includes('FAIL') ? 'red' : 'green' }}
										>
											<a
												href={
													process.env.REACT_APP_ENV === 'development'
														? `http://localhost:3030/display-report?reportName=${file}`
														: `/display-report?reportName=${file}`
												}
												target="_blank"
												style={{ color: 'inherit' }}
											>
												{file.split('.')[0]}
											</a>
										</Typography>
										<Divider sx={{ my: 2, borderBottomWidth: 3 }} />
									</Box>
								)
							})}
					</Box>
				</Box>
			</Box>
		</Container>
	)
}

export default Reports
