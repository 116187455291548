import React from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { useLocation } from 'react-router'

interface AutoCompleteProps {
	suggestions: string[]
	updateSelectionToPartner: Function
	defaultValue: string
	label: string
	id: string
	parseId?: boolean
	fromLocation: boolean
}

const SearchAutoComplete: React.FC<AutoCompleteProps> = ({
	suggestions,
	updateSelectionToPartner,
	defaultValue,
	label,
	id,
	parseId,
	fromLocation,
}) => {
	const { state } = useLocation()
	const [, setValue] = React.useState<string | null>('')

	const renderValue = () => {
		if (defaultValue === '' || !defaultValue) {
			return 'Select'
		}
		return defaultValue
	}

	return (
		<Autocomplete
			disablePortal
			isOptionEqualToValue={(option, selectedValue) => option === selectedValue}
			onChange={(event: React.SyntheticEvent, newValue: string | null) => {
				if (newValue) {
					const classId = newValue.split('-')[0]
					const classLabel = newValue.split('-').slice(0, 5).join()
					setValue(classLabel)
					updateSelectionToPartner(parseId ? classId : newValue)
				}
			}}
			value={renderValue()}
			id={id}
			options={suggestions}
			sx={{ width: '100%' }}
			renderInput={(params) => <TextField {...params} label={label} />}
		/>
	)
}
export default SearchAutoComplete
