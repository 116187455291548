import React, { useCallback, useState, useEffect } from 'react'
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Box,
	Container,
	Button,
	TextField,
} from '@mui/material'
import AddIcon from '@mui/icons-material/AddCircleOutlined'
import { useRecoilValue } from 'recoil'
import { useNavigate } from 'react-router'
import InformationBar from '../../components/InformationBar'
import BottomBar from '../../components/BottomBar'
import MultidayDropDown from '../../components/MultidayDropDown'
import AttendanceRow from './AttendanceRow'
import { participantIdsState, sessionListState } from '../../services/sessions'
import { useActiveSession } from '../../services/hooks/useActiveSession'
import { Session } from '../../types'
import InstructorNotes from '../../components/InstructorNotes'
import { useUpdateMultidaySessionsToServer } from '../../services/hooks/useSessionsFromServer'
import Locations from '../SessionDetails/Location'

const Attendance: React.FC = () => {
	const navigate = useNavigate()
	const { activeSession, updateActiveSession } = useActiveSession()
	const { date, lmsId } = activeSession
	const participantIds = useRecoilValue(participantIdsState)
	const [sessionsToUpdate, setSessionsToUpdate] = useState<Session[]>([])
	const updateSession = useUpdateMultidaySessionsToServer(sessionsToUpdate)
	const sessionList = useRecoilValue(sessionListState)
	const [selectedDate, setSelectedDate] = useState<string>(date)
	const [selectedSession, setSelectedSession] = useState<string>(lmsId)

	const [startTime, setStartTime] = useState('')
	const [ET, setEndTime] = useState('')
	const [location1, setLocation1] = useState('')
	const [location2, setLocation2] = useState('')

	useEffect(() => {
		const st =
			activeSession.startTime.length === 4
				? `0${activeSession.startTime}`
				: activeSession.startTime
		const et = activeSession.actualEndTime || activeSession.endTime
		const l1 = activeSession.actualLocation1 || activeSession.location1
		const l2 = activeSession.actualLocation2 || activeSession.location2

		setStartTime(st)
		setEndTime(et)
		setLocation1(l1)
		setLocation2(l2)
	}, [activeSession.lmsId])

	useEffect(() => {
		if (sessionsToUpdate?.length) {
			updateSession()
			setSessionsToUpdate([])
		}
	}, [sessionsToUpdate, updateSession])

	useEffect(() => {
		updateActiveSession({
			actualLocation1: location1,
		})
		updateActiveSession({
			actualLocation2: location2,
		})
	}, [location1, location2])

	const toWalkIn = useCallback(() => {
		navigate('/walkIn')
	}, [navigate])

	const toSessionDetails = useCallback(() => {
		navigate('/sessionDetails', { state: { origin: 'attendance' } })
	}, [navigate])

	const toCompletion = useCallback(() => {
		navigate('/completion')
	}, [navigate])

	const determineButtons = () => {
		// Handeling Multiday case
		if (lmsId.includes('_')) {
			if (sessionList[sessionList.length - 1].date === date) {
				return [
					{ label: 'Class Details', onClick: toSessionDetails },
					{ label: 'Mark Completion', onClick: toCompletion },
				]
			}
			return [
				{ label: 'Class Details', onClick: toSessionDetails },
				{ label: 'Mark Completion', onClick: toCompletion, disabled: true },
			]
		}
		// Default case
		return [
			{ label: 'Class Details', onClick: toSessionDetails },
			{ label: 'Mark Completion', onClick: toCompletion },
		]
	}

	const onTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.id === 'startTime') {
			setStartTime(event.target.value)
			updateActiveSession({
				actualStartTime: event.target.value,
			})
		} else {
			setEndTime(event.target.value)
			updateActiveSession({
				actualEndTime: event.target.value,
			})
		}
	}

	return (
		<Container disableGutters>
			<InformationBar currentPage="Attendance" displayClass={true} />
			{lmsId.includes('_') && (
				<MultidayDropDown
					selectedSession={selectedSession}
					setSelectedSession={setSelectedSession}
					currentPage="Attendance"
				/>
			)}
			{/* render on all multidays except for the last day */}
			{lmsId.includes('_') && sessionList[sessionList.length - 1].lmsId !== lmsId && (
				<>
					<Box sx={{ margin: '10px', display: 'flex', width: '100%' }}>
						<TextField
							className="fullWidth"
							id="startTime"
							label="Actual Start Time"
							type="time"
							value={startTime}
							onChange={onTimeChange}
							sx={{ margin: '0px', marginRight: '10px' }}
						/>
						<TextField
							className="fullWidth"
							id="endTime"
							label="Actual End Time"
							type="time"
							onChange={onTimeChange}
							value={ET}
							sx={{ margin: '0px' }}
						/>
					</Box>
					<Locations
						location1={location1}
						location2={location2}
						setLocation1={setLocation1}
						setLocation2={setLocation2}
						summary={true}
					/>
				</>
			)}
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Participant</TableCell>
						<TableCell>User ID</TableCell>
						<TableCell>Present</TableCell>
						<TableCell>Self Verified</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{participantIds.map((id) => {
						return <AttendanceRow key={id} id={id} sessionId={activeSession.lmsId} />
					})}
				</TableBody>
			</Table>
			<Box sx={{ display: 'flex', justifyContent: 'center' }}>
				<Button className="addItem" onClick={toWalkIn}>
					<AddIcon sx={{ fontSize: '35px' }} />
					Add Walk In
				</Button>
			</Box>
			{/* If the selected date is not the last one */}
			{lmsId.includes('_') && sessionList[sessionList.length - 1].date !== date && (
				<Box
					sx={{
						bgcolor: '#eee',
						textAlign: 'center',
						lineHeight: '2',
						position: 'fixed',
						bottom: '95px',
						width: '100%',
						maxWidth: '1200px',
						zIndex: '1101',
					}}
				>
					Mark Completion is only available on the last day of a Multiday Session
				</Box>
			)}
			<InstructorNotes
				sessionsToUpdate={sessionsToUpdate}
				setSessionsToUpdate={setSessionsToUpdate}
				selectedSession={selectedSession}
			/>
			<BottomBar instructions="" buttons={determineButtons()} />
		</Container>
	)
}

export default Attendance
