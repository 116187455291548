import { useCallback } from 'react'
import { useSetRecoilState, useRecoilState } from 'recoil'
import { getInstructorSessionsById, getStudentSessionsById, updateSession } from '../server'
import { sessionListState } from '../sessions'
import { Session } from '../../types'
import { dateObjToDateStrDash } from '../../utils/timeConversion'
import { removeDays } from '../../utils/removeDays'

export const useGetSessionsFromServer = (lmsId: string) => {
	const setSessionList = useSetRecoilState(sessionListState)
	const getServerSessionsFromServer = useCallback(() => {
		getInstructorSessionsById(lmsId).then((instructorSessions: Session[]) => {
			getStudentSessionsById(lmsId).then((studentSession: Session[]) => {
				setSessionList([...instructorSessions, ...studentSession])
			})
		})
	}, [setSessionList, lmsId])

	return getServerSessionsFromServer
}

export const useGetInstructorSessions = (lmsId: string) => {
	const setSessionList = useSetRecoilState(sessionListState)
	const getServerSessionsFromServer = useCallback(() => {
		getInstructorSessionsById(lmsId).then((instructorSessions: Session[]) => {
			// const currentDate = process.env.REACT_APP_DATE || dateObjToDateStrDash()
			// const newDate = removeDays(new Date(currentDate), 7)
			// const correctDates = instructorSessions.filter((item) => new Date(item.date) > newDate)
			setSessionList([...instructorSessions])
		})
	}, [setSessionList, lmsId])

	return getServerSessionsFromServer
}

export const useGetStudentSessions = (lmsId: string) => {
	const setSessionList = useSetRecoilState(sessionListState)
	const getServerSessionsFromServer = useCallback(() => {
		getStudentSessionsById(lmsId).then((studentSession: Session[]) => {
			setSessionList([...studentSession])
		})
	}, [setSessionList, lmsId])

	return getServerSessionsFromServer
}

export const useUpdateMultidaySessionsToServer = (updatedMultidaySessions: Session[]) => {
	const [sessionList, setSessionList] = useRecoilState(sessionListState)
	const updateMultidaySessionsToServer = useCallback(() => {
		// Add updated item into db
		updatedMultidaySessions.forEach((session: Session): void => {
			if (!session._id) return
			updateSession(session._id, session)
		})

		// Update Recoil with updated values (Function replaces old Session with New)
		const newSessionList = sessionList.map((oldSession) => {
			const updatedSession = updatedMultidaySessions.find(
				(newSession) => newSession._id === oldSession._id
			)
			return updatedSession ? { ...oldSession, ...updatedSession } : oldSession
		})
		setSessionList(newSessionList)
	}, [sessionList, setSessionList, updatedMultidaySessions])

	return updateMultidaySessionsToServer
}
