import * as React from 'react'
import { Box, Button } from '@mui/material'
import { useNavigate } from 'react-router'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
}

const InstructorVerification: React.FC<{
	title: string
	message: string
	updateParent: Function
	replaceInstructor: Function
	onLeaveField: Function
}> = ({ title, message, updateParent, replaceInstructor, onLeaveField }): JSX.Element => {
	const navigate = useNavigate()
	const [open, setOpen] = React.useState(true)
	const handleClose = () => {
		updateParent(false)
		setOpen(false)
	}

	const toSessionSelection = () => {
		setOpen(false)
		updateParent(false)
		navigate('/sessionSelection')
	}

	const onReplaceInstructor = () => {
		replaceInstructor()
		setOpen(false)
	}

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<Typography id="modal-modal-title" variant="h6" component="h2">
					{title}
				</Typography>
				<Typography id="modal-modal-description" sx={{ mt: 2 }}>
					{message}
				</Typography>
				<Box style={{ display: 'flex', justifyContent: 'space-around', margin: '10px' }}>
					<Button
						variant="contained"
						onClick={onReplaceInstructor}
						sx={{ minWidth: '150px' }}
					>
						Yes
					</Button>
					<Button
						variant="contained"
						onClick={toSessionSelection}
						sx={{ minWidth: '150px' }}
					>
						No
					</Button>
				</Box>
			</Box>
		</Modal>
	)
}

export default InstructorVerification
