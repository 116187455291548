import React from 'react'
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Container,
	Box,
	Button,
} from '@mui/material'
import { useNavigate, useLocation } from 'react-router'
import BottomBar from '../../components/BottomBar'
import InformationBar from '../../components/InformationBar'
import { useParticipant } from '../../services/hooks/useParticipant'
import { useActiveSession } from '../../services/hooks/useActiveSession'
import { militaryToStandard, dateYMDdashToMDYslash } from '../../utils/timeConversion'

const InstructorSelfVerification = () => {
	const navigate = useNavigate()
	const { state } = useLocation()

	const { participant, toggleIsSelfVerified } = useParticipant(state)
	const { lmsId, lastName, firstName } = participant
	const { activeSession } = useActiveSession()

	const handleVerification = () => {
		toggleIsSelfVerified()
		onBack()
	}

	const onBack = () => {
		navigate('/attendance')
	}

	return (
		<Container disableGutters>
			<InformationBar currentPage="Confirm Attendance" displayClass={false} />
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					lineHeight: '3',
					fontSize: '1rem',
					bgcolor: 'white',
				}}
			>
				<Box sx={{ ml: '30px' }}>User ID: {lmsId}</Box>
				<Box sx={{ mr: '30px' }}>First Name: {firstName}</Box>
				<Box sx={{ mr: '30px' }}>Last Name: {lastName}</Box>
			</Box>
			<Box
				sx={{
					bgcolor: '#eee',
					textAlign: 'center',
					lineHeight: '2',
					width: '100%',
					maxWidth: '1200px',
					zIndex: '1101',
				}}
			>
				Confirm Attendance for {activeSession.name}
			</Box>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Title</TableCell>
						<TableCell align="center">Class ID</TableCell>
						<TableCell align="center">Time</TableCell>
						<TableCell align="center">Date</TableCell>
						<TableCell align="center">Attendance</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow>
						<TableCell>{activeSession.name}</TableCell>
						<TableCell align="center">{activeSession.lmsId}</TableCell>
						<TableCell align="center">
							{militaryToStandard(activeSession.startTime)}
						</TableCell>
						<TableCell align="center">
							{dateYMDdashToMDYslash(activeSession.date)}
						</TableCell>
						<TableCell align="center">
							<Button
								onClick={handleVerification}
								type="button"
								variant="contained"
								sx={{
									minWidth: '200px',
									color: 'white',
									backgroundColor: 'red',
								}}
							>
								Confirm Attendance
							</Button>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
			<Box
				sx={{
					bgcolor: '#eee',
					textAlign: 'center',
					lineHeight: '2',
					position: 'fixed',
					bottom: '95px',
					width: '100%',
					maxWidth: '1200px',
					zIndex: '1101',
				}}
			>
				By selecting "Confirm Attendance" above, you acknowledge that you are the person
				named above, and that you were in attendance for the specified class session.
			</Box>
			<BottomBar instructions="" buttons={[{ label: 'Back', onClick: onBack }]} />
		</Container>
	)
}

export default InstructorSelfVerification
