import React, { useState, useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { Box, TextField } from '@mui/material'
import { useActiveSession } from '../services/hooks/useActiveSession'
import { Session } from '../types'
import { sessionListState } from '../services/sessions'

interface InstructorNotesProps {
	sessionsToUpdate: Session[]
	setSessionsToUpdate: Function
	selectedSession?: string
}

const InstructorNotes: React.FC<InstructorNotesProps> = ({
	sessionsToUpdate,
	setSessionsToUpdate,
	selectedSession,
}) => {
	const sessionList = useRecoilValue(sessionListState)
	const { activeSession, updateActiveSession } = useActiveSession()
	const [inputList, setInputList] = useState<string[]>([])
	const [newNotes, setNewNotes] = useState<string>(
		'notes' in activeSession ? activeSession.notes : ''
	)

	useEffect(() => {
		activeSession.notes && setNewNotes(activeSession.notes)
	}, [activeSession])

	useEffect(() => {
		const list: string[] = []
		sessionList.forEach((session, index) => {
			list[index] = session.notes || ''
		})
		setInputList(list)
	}, [sessionList])

	const handleNotesInputChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		index: number
	) => {
		const list = [...inputList]
		list[index] = e.target.value
		setInputList(list)
	}

	const handleNotesUpdate = (session: Session, index: number) => {
		if (!activeSession.lmsId.includes('_')) {
			const newSession = { ...session }
			newSession.notes = newNotes
			setSessionsToUpdate([...sessionsToUpdate, newSession])
			return
		}

		if (session.notes === inputList[index]) return
		const newSession = { ...session }
		newSession.notes = inputList[index]
		setSessionsToUpdate([...sessionsToUpdate, newSession])
	}

	return (
		<div>
			{!activeSession.lmsId.includes('_') && (
				<TextField
					className="fullWidth"
					id="instructor-notes"
					label="Instructor Notes"
					multiline
					value={newNotes}
					onChange={(event) => {
						setNewNotes(event.target.value)
					}}
					onBlur={() => {
						handleNotesUpdate(activeSession, 0)
					}}
				/>
			)}
			{(activeSession.lmsId.includes('_') &&
				selectedSession &&
				sessionList.map((session, index) => {
					if (session.lmsId === selectedSession) {
						return (
							<Box sx={{ display: 'flex', m: 1 }} key={session.lmsId}>
								<TextField
									className="fullWidth"
									id="instructor-notes"
									label={`Instructor Notes - ${session.date}: Session ${
										session.lmsId.split('_')[1]
									}`}
									multiline
									value={inputList[index]}
									onChange={(event) => {
										handleNotesInputChange(event, index)
									}}
									onBlur={() => {
										handleNotesUpdate(session, index)
									}}
								/>
							</Box>
						)
					}
					return null
				})) ||
				(activeSession.lmsId.includes('_') &&
					sessionList.map((session, index) => {
						return (
							<Box sx={{ display: 'flex', m: 1 }} key={session.lmsId}>
								<TextField
									className="fullWidth"
									id="instructor-notes"
									label={`Instructor Notes - ${session.date}: Session ${
										session.lmsId.split('_')[1]
									}`}
									multiline
									value={inputList[index]}
									onChange={(event) => {
										handleNotesInputChange(event, index)
									}}
									onBlur={() => {
										handleNotesUpdate(session, index)
									}}
								/>
							</Box>
						)
					}))}
		</div>
	)
}

export default InstructorNotes
