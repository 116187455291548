import { atom } from 'recoil'
import { User } from '../types'

const defaultUserState: User = {
	_id: '',
	firstName: '',
	lastName: '',
	email: '',
	route: '',
	lmsId: '',
	roles: [],
	instructorId: '',
}

export const userState = atom({
	key: 'user',
	default: defaultUserState,
})
