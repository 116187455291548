import React, { useState } from 'react'
import { AppBar, Box, Toolbar, IconButton, Typography, Container, Button } from '@mui/material'
import AccountCircle from '@mui/icons-material/AccountCircle'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import HomeIcon from '@mui/icons-material/Home'
import { useRecoilValue } from 'recoil'
import { useNavigate } from 'react-router'
import { userState } from '../services/user'
import quickRosterLogo from '../assets/quickroster-logo.png'
import Modal from './Modal'
import AllSessionsReport from './AllSessionsReport'

const TopBar: React.FC<{ className?: string }> = ({ className }) => {
	const user = useRecoilValue(userState)
	const navigate = useNavigate()
	const [helpMessage, setHelpMessage] = useState<React.ReactElement | null>(null)

	const hasUser = user._id.length > 0

	const returnHome = () => {
		navigate('/')
	}

	const closeModal = () => {
		setHelpMessage(null)
	}

	const setHelpModal = () => {
		setHelpMessage(
			<div>
				<b>For technical concerns call:</b>
				<br />
				<a href="tel:877-876-4383">1.877.876.4383</a>
				<br />
				<br />
				<b>E-mail us at:</b>
				<br />
				<a href="mailto:dte.training@trainingsupportadmin.com">
					dte.training@trainingsupportadmin.com
				</a>
			</div>
		)
	}

	return (
		<Container sx={{ height: '64px' }}>
			{helpMessage && (
				<Modal
					title="Support Contact Info"
					message={helpMessage}
					updateParent={closeModal}
				/>
			)}
			<AppBar elevation={0} sx={{ bgcolor: 'white' }}>
				<Container sx={{ bgcolor: 'black', minHeight: '64px' }}>
					<Toolbar>
						<Box sx={{ display: { xs: 'none', md: 'flex' } }}>
							<IconButton
								size="large"
								aria-label="show more"
								aria-haspopup="true"
								sx={{ display: 'flex', flexDirection: 'column' }}
								onClick={setHelpModal}
							>
								<HelpOutlineIcon color="primary" />
							</IconButton>
						</Box>
						<Box sx={{ flexGrow: 0.8 }} />
						<Box sx={{ flexGrow: 0.5 }} />
						<Box sx={{ display: { xs: 'none', md: 'flex' } }}>
							<IconButton
								size="large"
								aria-label="show more"
								aria-haspopup="true"
								sx={{
									display: 'flex',
									flexDirection: 'column',
									marginRight: '-25px',
									marginTop: '-6px',
								}}
								onClick={returnHome}
							>
								<HomeIcon sx={{ color: 'white' }} />
							</IconButton>
						</Box>
						<Box
							component="img"
							sx={{ width: '200px', cursor: 'pointer' }}
							alt="quick roster logo"
							width="auto"
							src={quickRosterLogo}
							onClick={returnHome}
						/>
						<Box sx={{ flexGrow: 1 }} />
						<Box sx={{ display: { xs: 'none', md: 'flex' }, color: 'white' }}>
							{user.roles.includes('reporter') && (
								<Button
									variant="contained"
									sx={{ minWidth: '50px' }}
									type="button"
									onClick={() => {
										navigate('/reports')
									}}
								>
									REPORTS
								</Button>
							)}
						</Box>
						<Box sx={{ display: { xs: 'none', md: 'flex' } }}>
							{hasUser && (
								<IconButton
									size="large"
									aria-label="show more"
									aria-haspopup="true"
									sx={{ display: 'flex', flexDirection: 'column' }}
								>
									<AccountCircle color="primary" />
									<Typography color="primary">
										{user.firstName} {user.lastName}
									</Typography>
								</IconButton>
							)}
						</Box>
					</Toolbar>
				</Container>
			</AppBar>
		</Container>
	)
}

export default TopBar
