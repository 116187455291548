import React, { useState, ChangeEvent } from 'react'
import { useRecoilValue } from 'recoil'
import { Container, TextField, Box } from '@mui/material'
import { useNavigate } from 'react-router'
import { Session } from '../../types'
import {
	addMinutesToTimeString,
	dateObjToDateStrDash,
	dateYMDdashToMDYslash,
} from '../../utils/timeConversion'
import InformationBar from '../../components/InformationBar'
import BottomBar from '../../components/BottomBar'
import { createSession } from '../../services/server'
import { useClasses } from '../../services/hooks/useClasses'
import { userState } from '../../services/user'
import Locations from '../SessionDetails/Location'
import SearchAutoComplete from '../../components/SearchAutoComplete'

const ClassCreation: React.FC = () => {
	const navigate = useNavigate()
	const user = useRecoilValue(userState)
	const classList = useClasses()
	const [selectedClass, setSelectedClass] = useState(' ')
	const [title, setTitle] = useState('')
	const [location1, setLocation1] = useState('')
	const [location2, setLocation2] = useState('')
	const [time, setTime] = useState('')
	const [showAlert, setAlert] = useState('')

	const onClassSelect = (value: string) => {
		if (!value || value.indexOf('-') < 0) return
		const id = value.split('-')[0]
		const sessionClass = classList.filter((option) => option.lmsId === id)[0]
		setSelectedClass(value)
		setTitle(sessionClass.title)
	}

	const onTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
		setTime(event.target.value)
	}

	const onPrevious = () => {
		navigate('/')
	}

	const onCreate = () => {
		if (!selectedClass) {
			setAlert('Please select a class.')
			return
		}

		if (location1 === '') {
			setAlert('Please select a facility.')
			return
		}
		if (location2 === '') {
			setAlert('Please select a room.')
			return
		}

		setAlert('')

		const id = selectedClass.split('-')[0]

		const sessionClass = classList.filter((option) => option.lmsId === id)[0]

		let endTime = addMinutesToTimeString(
			time || '6:00',
			Math.round(parseFloat(sessionClass.duration) * 60)
		).toString()

		if (endTime >= '24:00') {
			endTime = '23:59'
		}

		const newSession: Session = {
			lmsId: '',
			name: sessionClass.title,
			instructorId: user.instructorId,
			date: dateObjToDateStrDash(),
			startTime: time || '6:00',
			endTime,
			sessionTemplateId: sessionClass.lmsId,
			location1,
			location2,
			status: 0,
			participants: [],
			notes: '',
			actualStartTime: time || '6:00',
			actualEndTime: endTime || '6:00',
			actualInstructorId: user.instructorId,
			actualLocation1: location1,
			actualLocation2: location2,
		}
		createSession(newSession).then((session: Session) => {
			navigate('/')
		})
	}

	const returnAlert = () => {
		if (showAlert !== '') {
			return (
				<div
					style={{
						marginTop: '10px',
						fontWeight: 'bold',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
					}}
				>
					<h3 style={{ color: 'red' }}>{showAlert}</h3>
				</div>
			)
		}
		return ''
	}

	return (
		<Container disableGutters>
			<InformationBar currentPage="Summary Review" displayClass={false} />
			<div
				style={{
					marginTop: '10px',
					fontWeight: 'bold',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
				}}
			>
				<h2>For Single Day Class ONLY, Multi-day class must be scheduled in Apsire</h2>
			</div>
			{returnAlert()}
			<Box sx={{ padding: '40px' }}>
				<Box sx={{ margin: '10px -10px 10px 10px' }}>
					<SearchAutoComplete
						suggestions={classList.map(
							(classItem) => `${classItem.lmsId}-${classItem.title}`
						)}
						updateSelectionToPartner={onClassSelect}
						defaultValue={selectedClass}
						label="Item ID"
						id="item-id"
						fromLocation={false}
					/>
				</Box>

				<TextField
					className="fullWidth"
					id="title"
					label="Class Title"
					value={title || 'Select Item ID'}
					disabled
				/>
				<TextField
					className="fullWidth"
					id="instructor"
					label="Instructor Id"
					value={user.instructorId}
					disabled
				/>
				<TextField
					className="fullWidth"
					id="Date"
					label="Date"
					value={dateYMDdashToMDYslash(dateObjToDateStrDash())}
					disabled
				/>
				<TextField
					className="fullWidth"
					id="startTime"
					type="time"
					label="Start Time"
					defaultValue="06:00"
					onChange={onTimeChange}
				/>
				<Locations
					location1={location1}
					location2={location2}
					setLocation1={setLocation1}
					setLocation2={setLocation2}
					summary={false}
				/>
			</Box>
			<BottomBar
				instructions="Enter the details for the new session in the form above.  Select “Create” to generate the new class"
				buttons={[
					{ label: 'Previous', onClick: onPrevious },
					{ label: 'Create', onClick: onCreate, disabled: selectedClass === ' ' },
				]}
			/>
		</Container>
	)
}

export default ClassCreation
