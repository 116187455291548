import { atom, atomFamily, selectorFamily, DefaultValue, selector } from 'recoil'
import { Participant, Session, SessionStatus, CompletionStatus } from '../types'

export const defaultSessionState: Session = {
	_id: '',
	lmsId: '',
	sessionTemplateId: '',
	name: '',
	instructorId: '',
	startTime: '',
	endTime: '',
	date: '',
	location1: '',
	location2: '',
	status: SessionStatus.NOT_STARTED,
	participants: [],
	notes: '',
}

export const sessionStateFamily = atomFamily<Session, string>({
	key: 'sessionStateFamily',
	default: defaultSessionState,
})

export const sessionIdsState = atom<string[]>({
	key: 'sessionIdsState',
	default: [],
})

export const sessionState = selectorFamily<Session, string>({
	key: 'sessionState',
	get:
		(id) =>
		({ get }) =>
			get(sessionStateFamily(id)),
	set:
		(id) =>
		({ get, set, reset }, newValue) => {
			if (newValue instanceof DefaultValue) {
				reset(sessionStateFamily(id))
				return
			}
			set(sessionStateFamily(id), newValue)
			if (get(sessionIdsState).find((foundId) => foundId === id)) return
			set(sessionIdsState, (prev) => [...prev, id])
		},
})

export const sessionListState = selector<Session[]>({
	key: 'sessionListState',
	get: ({ get }) => {
		const sessionIds = get(sessionIdsState)
		return sessionIds.map((id) => {
			return get(sessionState(id))
		})
	},
	set: ({ set, get }, newSessions) => {
		// TODO: find a good way to clear out the sessionAtomFamily
		const newSessionIds: string[] = []
		if (newSessions instanceof DefaultValue) return

		newSessions.forEach((newSession) => {
			if (!newSession._id) return
			set(sessionStateFamily(newSession._id), newSession)
			if (!newSessionIds.find((id) => id === newSession._id))
				newSessionIds.push(newSession._id)
		})
		set(sessionIdsState, newSessionIds)
		set(activeSessionIdState, get(activeSessionIdState))
	},
})

// Active Session
export const activeSessionStateAtom = atom<Session>({
	key: 'activeSessionStateAtom',
	default: defaultSessionState,
})
export const activeSessionState = selector<Session>({
	key: 'activeSessionState',
	get: ({ get }) => get(activeSessionStateAtom),
	set: ({ set, get }, newActiveSession) => {
		if (newActiveSession instanceof DefaultValue) return
		set(activeSessionStateAtom, newActiveSession)
	},
})

const activeSessionIdStateAtom = atom<string>({
	key: 'activeSessionIdStateAtom',
	default: '',
})

export const activeSessionIdState = selector<string>({
	key: 'activeSessionIdState',
	get: ({ get }) => get(activeSessionIdStateAtom),
	set: ({ set, get }, id) => {
		set(activeSessionIdStateAtom, id)

		if (id instanceof DefaultValue) return
		const updateActiveSessionRecoilStates = () => {
			const activeSession = get(sessionStateFamily(id))

			const newParticipants = activeSession.participants.map((participant, index) => {
				return { ...participant, _id: `${id}_${index}` }
			})
			set(participantListState, newParticipants)

			set(activeSessionStateAtom, activeSession)
		}
		updateActiveSessionRecoilStates()
	},
})

// Participants
export const defaultParticipantState: Participant = {
	_id: '',
	lmsId: '',
	firstName: '',
	lastName: '',
	attendance: false,
	completion: CompletionStatus.NOT_SET,
	isSelfVerified: false,
	selfVerifiedDate: '',
	whoVerified: '',
}

export const participantStateFamily = atomFamily<Participant, string>({
	key: 'participantStateFamily',
	default: defaultParticipantState,
})

export const participantIdsState = atom<string[]>({
	key: 'participantIdsState',
	default: [],
})

export const participantState = selectorFamily<Participant, string>({
	key: 'participantState',
	get:
		(id) =>
		({ get }) =>
			get(participantStateFamily(id)),
	set:
		(id) =>
		({ get, set, reset }, newValue) => {
			if (newValue instanceof DefaultValue) {
				reset(participantStateFamily(id))
				return
			}
			set(participantStateFamily(id), newValue)

			const activeSession = get(activeSessionState)

			if (!activeSession._id) return

			set(participantIdsState, (prev) => [...prev, id])
		},
})

export const participantListState = selector<Participant[]>({
	key: 'participantsListState',
	get: ({ get }) => {
		const participantIds = get(participantIdsState)
		return participantIds.map((id) => {
			return get(participantState(id))
		})
	},
	set: ({ set }, newParticipants) => {
		const newParticipantIds: string[] = []
		if (newParticipants instanceof DefaultValue) return

		newParticipants.forEach((newParticipant) => {
			set(participantStateFamily(newParticipant._id), newParticipant)
			newParticipantIds.push(newParticipant._id)
		})
		set(participantIdsState, newParticipantIds)
	},
})
