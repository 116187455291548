import React, { useCallback, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { useNavigate } from 'react-router'
import { Table, TableBody, TableCell, TableHead, TableRow, Container, Box } from '@mui/material'
import BottomBar from '../../components/BottomBar'
import InformationBar from '../../components/InformationBar'
import { participantIdsState, sessionListState } from '../../services/sessions'
import SummaryReviewRow from './SummaryReviewRow'
import { useActiveSession } from '../../services/hooks/useActiveSession'
import { useGetConfig } from '../../services/hooks/useConfig'
import { Session } from '../../types'

const SummaryReview = () => {
	const { activeSession } = useActiveSession()
	const { lmsId, notes } = activeSession
	const sessionList = useRecoilValue(sessionListState)
	const participantIds = useRecoilValue(participantIdsState)
	const navigate = useNavigate()
	const getConfig = useGetConfig()
	const [canSubmitAdhocId, setCanSubmitAdhocId] = useState(false)

	useEffect(() => {
		getConfig?.canSubmitAdhocId === true && setCanSubmitAdhocId(true)
	}, [getConfig])

	const toSessionSummary = useCallback(() => {
		navigate('/SessionSummary')
	}, [activeSession._id, activeSession.lmsId, navigate, sessionList, canSubmitAdhocId])

	const toCompletion = useCallback(() => {
		navigate('/completion')
	}, [navigate])

	const renderButtons = () => {
		// Handeling Multiday case
		if (lmsId.substring(0, 2) === 'QR' && !canSubmitAdhocId) {
			return [
				{ label: 'Mark Completion', onClick: toCompletion },
				{
					label: 'Summary',
					onClick: toSessionSummary,
					disabled: true,
				},
			]
		}
		// Default case
		return [
			{ label: 'Mark Completion', onClick: toCompletion },
			{
				label: 'Summary',
				onClick: toSessionSummary,
			},
		]
	}

	return (
		<Container disableGutters>
			<InformationBar currentPage="Summary Review" displayClass={true} />
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Participant</TableCell>
						<TableCell align="center">User ID </TableCell>
						<TableCell align="center">Status</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{participantIds.map((id) => {
						return <SummaryReviewRow key={id} id={id} />
					})}
				</TableBody>
			</Table>
			{!lmsId.includes('_') && (
				<Box
					id="comments"
					sx={{
						padding: '5px 0px 5px 10px',
						display: 'flex',
						// width: '100%',
						fontStyle: 'italic',
						mx: 1,
						border: '1px solid rgba(0, 0, 0, 0.38)',
						borderRadius: '4px',
					}}
				>
					{'notes' in activeSession && notes !== '' ? notes : 'No Instructor Notes'}
				</Box>
			)}
			{lmsId.includes('_') &&
				sessionList.map((session: Session) => {
					return (
						<Box
							key={session.lmsId}
							sx={{
								visibility: 'visible',
								display: 'block',
								border: 1,
								borderRadius: 1,
								borderColor: 'grey.300',
								fontStyle: 'italic',
								m: 1,
								p: 0.5,
							}}
						>
							{`${session.date}: Session ${session.lmsId.split('_')[1]}: ${
								session.notes
							}`}
						</Box>
					)
				})}

			<Box
				sx={{
					bgcolor: '#eee',
					textAlign: 'center',
					lineHeight: '2',
					position: 'fixed',
					bottom: '95px',
					width: '100%',
					maxWidth: '1200px',
					zIndex: '1101',
				}}
			>
				Please review this summary to make sure all the information is correct.
			</Box>
			<BottomBar instructions="" buttons={renderButtons()} />
		</Container>
	)
}

export default SummaryReview
