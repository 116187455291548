import { createTheme } from '@mui/material'

const primary = {
	main: '#7BA7BC',
	light: '#cadbe4',
	dark: '#567483',
}

const secondary = {
	main: '#7BA7BC',
	light: '#7BA7BC',
	dark: '#7BA7BC',
}

const theme = createTheme({
	palette: {
		primary,
		secondary,
	},
	components: {
		MuiTableRow: {
			styleOverrides: {
				root: {
					'&:nth-of-type(even)': {
						backgroundColor: '#eee',
						'& .MuiTableCell-body': {
							borderRight: 'solid 1px white',
						},
					},
					'&:hover': {
						backgroundColor: primary.light,
					},
				},
				head: {
					backgroundColor: '#9e9e9e',
					'&:hover': {
						backgroundColor: '#9e9e9e',
					},
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				head: {
					color: 'white',
					borderRight: 'solid 1px white',
					'&:last-of-type': {
						borderRight: 'none',
					},
				},
				body: {
					borderRight: 'solid 1px #eee',
					'&:last-of-type': {
						borderRight: 'none',
					},
				},
			},
		},
		MuiButton: {
			variants: [{ props: { className: 'addItem' }, style: { fontSize: 'large' } }],
		},
		MuiTextField: {
			variants: [
				{
					props: { className: 'fullWidth' },
					style: {
						width: '100%',
						margin: '10px',
					},
				},
			],
		},
	},
})

export default theme
