import { useCallback } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { participantState } from '../sessions'
import { CompletionStatus, Participant } from '../../types'
import { patchParticipant } from '../server'
import { useActiveSession } from './useActiveSession'
import { userState } from '../user'

export const useParticipant = (id: string) => {
	const [participant] = useRecoilState<Participant>(participantState(id))
	const { activeSession } = useActiveSession()
	const user = useRecoilValue(userState)
	const updateParticipant = useCallback(
		(updateValues: Partial<Participant>) => {
			if (!activeSession._id) return

			patchParticipant(id, updateValues, activeSession._id)
		},
		[id, activeSession._id]
	)

	const toggleAttendance = useCallback(() => {
		const attendance = !participant.attendance
		updateParticipant({ attendance })
	}, [participant.attendance, updateParticipant])

	const toggleCompletion = useCallback(() => {
		let completion = CompletionStatus.COMPLETE
		if (participant.completion === CompletionStatus.COMPLETE)
			completion = CompletionStatus.INCOMPLETE
		updateParticipant({ completion })
	}, [participant.completion, updateParticipant])

	const toggleIsSelfVerified = useCallback(() => {
		const isSelfVerified = !participant.isSelfVerified
		const selfVerifiedDate = isSelfVerified ? new Date().toString() : ''
		updateParticipant({ isSelfVerified, selfVerifiedDate, whoVerified: user.lmsId })
	}, [participant.isSelfVerified, updateParticipant])

	return {
		participant,
		updateParticipant,
		toggleAttendance,
		toggleIsSelfVerified,
		toggleCompletion,
	}
}
