import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import {
	Alert,
	Avatar,
	Box,
	Button,
	Container,
	CssBaseline,
	TextField,
	Typography,
} from '@mui/material'
import { useNavigate } from 'react-router'
import { authState, AUTH_STATUS, usePasswordResetRequest } from '../../services/authentication'
import { validateEmail } from '../../utils/validatation'

const ResetPasswordRequest: React.FC<{ className?: string }> = ({ className }) => {
	const auth = useRecoilValue(authState)
	const [errorMessage, setErrorMessage] = useState('')
	const requestPasswordReset = usePasswordResetRequest()
	const navigate = useNavigate()

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		const email = data.get('email')?.toString() || ''

		if (!validateEmail(email)) {
			setErrorMessage('Invalid Email Address.')
			return
		}

		if (auth.status === AUTH_STATUS.PASSWORD_RESET_FAILED)
			setErrorMessage(`You've entered the incorrect Email. Please try again.`)
		else setErrorMessage('')

		requestPasswordReset(email)
	}

	useEffect(() => {
		if (auth.status === AUTH_STATUS.PASSWORD_RESET_FAILED)
			setErrorMessage(`You've entered the incorrect Email. Please try again.`)
		else setErrorMessage('')
	}, [auth.status, navigate])

	return (
		<div data-test="ResetPasswordRequest" className={`ResetPasswordRequest ${className || ''}`}>
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<Box
					sx={{
						marginTop: 8,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }} />
					<Typography component="h1" variant="h5">
						Request Password Reset
					</Typography>
					{auth.status === AUTH_STATUS.PASSWORD_RESET_REQUESTED && (
						<Typography>
							An email has been sent with instructions on resetting your password
						</Typography>
					)}
					{auth.status !== AUTH_STATUS.PASSWORD_RESET_REQUESTED && (
						<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
							<TextField
								margin="normal"
								required
								fullWidth
								id="email"
								label="Email Address"
								name="email"
								autoComplete="email"
								autoFocus
							/>
							{errorMessage.length > 0 && (
								<Alert severity="error">{errorMessage}</Alert>
							)}{' '}
							<Button
								type="submit"
								fullWidth
								variant="contained"
								sx={{ mt: 3, mb: 2 }}
							>
								Request Password Reset
							</Button>
						</Box>
					)}
				</Box>
			</Container>
		</div>
	)
}

export default ResetPasswordRequest
