import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import {
	Avatar,
	Box,
	Button,
	Container,
	CssBaseline,
	Grid,
	TextField,
	Typography,
	Link,
	Alert,
} from '@mui/material'
import { useNavigate } from 'react-router'
import { authState, AUTH_STATUS, useAuthenticator } from '../../services/authentication'

import { validateEmail } from '../../utils/validatation'

const Login: React.FC<{ className?: string }> = ({ className }) => {
	const auth = useRecoilValue(authState)
	const [errorMessage, setErrorMessage] = useState('')
	const authenticateUser = useAuthenticator()
	const navigate = useNavigate()

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		const email = data.get('email')?.toString().toLowerCase() || ''
		const password = data.get('password')?.toString() || ''

		if (!validateEmail(email)) {
			setErrorMessage('Invalid Email Address.')
			return
		}

		if (password.length < 3) {
			setErrorMessage('Invalid Password.')
			return
		}

		if (auth.status === AUTH_STATUS.AUTHENTICATION_FAILED)
			setErrorMessage(`You've entered the incorrect Email or Password. Please try again.`)
		else setErrorMessage('')

		authenticateUser({ email, password })
	}

	useEffect(() => {
		if (auth.status === AUTH_STATUS.AUTHENTICATION_FAILED)
			setErrorMessage(`You've entered the incorrect Email or Password. Please try again.`)
		else setErrorMessage('')
		if (auth.status === AUTH_STATUS.AUTHENTICATED) navigate('/')
	}, [auth.status, navigate])

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<Box
				sx={{
					marginTop: 8,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }} />
				<Typography component="h1" variant="h5">
					Sign in
				</Typography>
				<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
					{auth.status === AUTH_STATUS.NEW_ACCOUNT && (
						<Typography>New Account Created! Please log in.</Typography>
					)}
					{process.env.REACT_APP_ENV === 'development' && (
						<>
							<TextField
								margin="normal"
								required
								fullWidth
								id="email"
								label="Email Address"
								name="email"
								autoComplete="email"
								autoFocus
							/>
							<TextField
								margin="normal"
								required
								fullWidth
								name="password"
								label="Password"
								type="password"
								id="password"
								autoComplete="current-password"
							/>
							{errorMessage.length > 0 && (
								<Alert severity="error">{errorMessage}</Alert>
							)}
							<Button
								type="submit"
								fullWidth
								variant="contained"
								sx={{ mt: 3, mb: 2 }}
							>
								Sign In
							</Button>
							<Grid container>
								<Grid item xs>
									<Link href="/auth/resetPasswordRequest" variant="body2">
										Forgot password?
									</Link>
								</Grid>
								<Grid item>
									<Link href="/auth/signup" variant="body2">
										Don't have an account? Sign Up
									</Link>
								</Grid>
							</Grid>
						</>
					)}

					<Link href="/saml">
						<Button type="button" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
							Aspire Single Sign-on
						</Button>
					</Link>
				</Box>
			</Box>
		</Container>
	)
}

export default Login
