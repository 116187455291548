import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Box,
	Container,
	Button,
} from '@mui/material'
import { useNavigate } from 'react-router'
import AddIcon from '@mui/icons-material/AddCircleOutlined'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import CircleIcon from '@mui/icons-material/Circle'
import InformationBar from '../../components/InformationBar'
import { sessionIdsState, sessionListState, activeSessionIdState } from '../../services/sessions'
import { userState } from '../../services/user'
import { getAllSessions } from '../../services/server'
import { authState, AUTH_STATUS } from '../../services/authentication'
import { useGetInstructorSessions } from '../../services/hooks/useSessionsFromServer'
import SessionSelectionRow from './SessionSelectionRow'
import SearchAutoComplete from '../../components/SearchAutoComplete'
import { Session } from '../../types'
import BottomBar from '../../components/BottomBar'
import {
	dateObjToDateStrDash,
	dateYMDdashToMDYslash,
	formatDateStringToYMD,
	dayjsMilitaryToStandard,
} from '../../utils/timeConversion'
import { removeDays } from '../../utils/removeDays'

const SessionSelection = (): JSX.Element => {
	const auth = useRecoilValue(authState)
	const sessionIds = useRecoilValue(sessionIdsState)
	const user = useRecoilValue(userState)
	const getSessionsFromServer = useGetInstructorSessions(user.instructorId)
	const navigate = useNavigate()
	const [sessionList, setSessionList] = useRecoilState(sessionListState)
	const setActiveSessionId = useSetRecoilState(activeSessionIdState)
	const [allSessions, setAllSessions] = useState<Session[] | []>([])
	const [allSessionDetails, setAllSessionDetails] = useState<string[] | []>([])

	useEffect(() => {
		if (auth.status !== AUTH_STATUS.AUTHENTICATED) return
		getSessionsFromServer()
	}, [auth.status, getSessionsFromServer])

	useEffect(() => {
		setActiveSessionId('')
	}, [setActiveSessionId])

	useEffect(() => {
		const fetchAllSessions = async () => {
			try {
				const allSessionsList = await getAllSessions()
				const newSessionList = allSessionsList.sort((a, b) => {
					return a.status - b.status || `${a.date}`.localeCompare(b.date)
				})
				setSessionList(newSessionList)
				setAllSessions(newSessionList)
				// Make sure we don't get any future classes
				const currentSessionList = newSessionList.filter(
					(session) => new Date(session.date) <= new Date()
				)
				const updatedList = addressMultiDaySessions(currentSessionList)

				const sessionDetailsList = updatedList.map((item: Session) => {
					const sessionDetails = `${item.lmsId.split('_')[0]} - ${
						item.name
					} - ${dateYMDdashToMDYslash(item.date)} - ${dayjsMilitaryToStandard(
						item.startTime
					)} - ${item.location2}`
					return sessionDetails
				})
				setAllSessionDetails(sessionDetailsList)
			} catch (error) {
				console.error('Server Connection has been lost:', error)
				navigate('/auth/login')
			}
		}
		fetchAllSessions()
		return () => {
			setAllSessions([])
		}
	}, [navigate])

	/* All days of a multi-day session were showing up in the drop down list
	After discussions with Tony G. we are only showing the latest attendable day */
	const addressMultiDaySessions = (currentSessionList: Session[]) => {
		const checkAr: Array<string> = []
		const filterAr: Array<string> = []
		currentSessionList.forEach((session: Session) => {
			const idAR = session.lmsId.split('_')

			// If it is a multiday, check to see if the multiday is already in the array checkAr
			// If not, add it.
			// If it is already in checkAr, remove the previous multiday from filterAr, then add
			// the current session and update the multiday in checkAr with the current session's day.
			if (idAR.length > 1) {
				const i = checkAr.indexOf(idAR[0])
				if (i < 0) {
					checkAr.push(idAR[0], idAR[1])
					filterAr.push(session.lmsId)
				} else {
					filterAr.splice(filterAr.indexOf(`${checkAr[i]}_${checkAr[i + 1]}`), 1)
					filterAr.push(session.lmsId)
					const multidayNum = idAR[1]
					checkAr[i + 1] = multidayNum
				}
			} else {
				filterAr.push(session.lmsId)
			}
		})
		const updatedList = currentSessionList.filter((session) => filterAr.includes(session.lmsId))
		return updatedList
	}

	const addNewClass = () => {
		navigate('/classCreation', { state: { origin: 'sessionSelection' } })
	}

	// new Date().getDate() > new Date(sessionClass.date).getDate() &&
	// 				new Date(correctClass.date).getDate() < new Date(sessionClass.date).getDate()

	const onClassSearchSelection = (classId: string) => {
		const selectedClass = allSessions.filter((item: Session) => {
			return item.lmsId && item.lmsId.trim().split('_')[0] === classId.trim()
		})
		let correctClass: Session | undefined
		if (selectedClass.length === 1) {
			const onlyClass = selectedClass[0]
			correctClass = onlyClass
		} else {
			// if it's less than current date, but greater than correctClass date then we replace correctClass with it
			// TODO: Need to improve messy conditional
			selectedClass.forEach((sessionClass: Session) => {
				if (
					correctClass === undefined &&
					new Date().getDate() > new Date(sessionClass.date).getDate()
				) {
					correctClass = sessionClass
				} else if (
					new Date().getDate() > new Date(sessionClass.date).getDate() &&
					new Date((correctClass as Session).date).getDate() <
						new Date(sessionClass.date).getDate()
				) {
					correctClass = sessionClass
				}
			})
		}
		setSessionList([...sessionList, correctClass as Session])
		correctClass?._id && setActiveSessionId(correctClass._id)
		navigate('/sessionDetails', { state: { origin: 'searchBar' } })
	}

	const toSelfVerification = () => {
		navigate('/selfVerification')
	}

	return (
		<Container disableGutters>
			<InformationBar currentPage="Instructor Class Selection" displayClass={false} />
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Title</TableCell>
						<TableCell align="center">Class ID</TableCell>
						<TableCell align="center">Time</TableCell>
						<TableCell align="center">Date</TableCell>
						<TableCell align="center">Location</TableCell>
						<TableCell align="center">Status</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{sessionIds.map((id) => {
						return (
							<SessionSelectionRow
								key={id}
								id={id}
								userId={user.instructorId}
								userRoles={user.roles}
							/>
						)
					})}
				</TableBody>
			</Table>
			<Box sx={{ display: 'flex', justifyContent: 'center' }}>
				<Button className="addItem" onClick={addNewClass}>
					<AddIcon sx={{ fontSize: '35px' }} />
					Add New Class
				</Button>
			</Box>
			<Box
				sx={{
					margin: '0 auto',
					width: '100%',
				}}
			>
				<SearchAutoComplete
					suggestions={[' ', ...new Set(allSessionDetails)]}
					updateSelectionToPartner={onClassSearchSelection}
					defaultValue={' '}
					label="Class Search"
					id="Class Search Drop Down"
					parseId={true}
					fromLocation={false}
				/>
			</Box>
			<Box
				sx={{
					bgcolor: '#eee',
					textAlign: 'center',
					lineHeight: '2',
					position: 'fixed',
					bottom: '100px',
					width: '100%',
					maxWidth: '1200px',
					zIndex: '1101',
				}}
			>
				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<Box sx={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
						Not started : <CircleOutlinedIcon color="primary" fontSize="large" />
					</Box>
					<Box sx={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
						In progress :{' '}
						<svg width="1em" height="1em" fontSize="2.1875rem" viewBox="0 0 24 24">
							<path
								d="M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2m0 2a8 8 0 0 1 8 8a8 8 0 0 1-8 8V4z"
								fill="#FDDA0D"
							/>
						</svg>
					</Box>
					<Box sx={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
						Complete : <CircleIcon color="success" fontSize="large" />
					</Box>
				</Box>
			</Box>
			<BottomBar
				instructions=""
				buttons={[{ label: 'Student View', onClick: toSelfVerification }]}
			/>
		</Container>
	)
}

export default SessionSelection
