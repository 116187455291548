import { AppBar, Button, Container } from '@mui/material'
import React from 'react'
// import ArrowBackIcon from '@mui/icons-material/ArrowBack'
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

interface NavButton {
	label: string
	onClick: () => void
	disabled?: boolean
}

const BottomBar: React.FC<{
	instructions?: string
	buttons?: NavButton[]
	bottomGutter?: number
}> = ({ instructions, buttons = [], bottomGutter = 150 }) => {
	const displayLeftArrow = (numButtons: number, position: number) => {
		if (numButtons >= 2 && position === 0) return <ChevronLeftIcon />
		return <></>
	}
	const displayRightArrow = (numButtons: number, position: number) => {
		if (numButtons >= 2 && position === numButtons - 1) return <ChevronRightIcon />
		return <></>
	}
	return (
		<Container sx={{ height: `${bottomGutter}px` }}>
			<AppBar
				elevation={0}
				position="fixed"
				sx={{ top: 'auto', bottom: '31px', bgcolor: 'white' }}
			>
				{instructions && (
					<Container sx={{ bgcolor: 'grey.300', padding: '20px' }}>
						{instructions}
					</Container>
				)}
				{buttons && (
					<Container
						sx={{
							padding: '20px',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-around',
						}}
					>
						{buttons.map(({ label, onClick, disabled = false }, index) => (
							<Button
								key={label}
								variant="contained"
								onClick={onClick}
								sx={{ minWidth: '200px' }}
								disabled={disabled}
								// startIcon={buttons.length > 1 && index === 0 && <ChevronLeftIcon />}
								// endIcon={
								// 	buttons.length > 1 &&
								// 	index === buttons.length - 1 && <ChevronRightIcon />
								// }
								startIcon={displayLeftArrow(buttons.length, index)}
								endIcon={displayRightArrow(buttons.length, index)}
							>
								{label}
							</Button>
						))}
					</Container>
				)}
			</AppBar>
		</Container>
	)
}

export default BottomBar
