import { Box, Container, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { useNavigate, useLocation } from 'react-router'
import InformationBar from '../../components/InformationBar'
import BottomBar from '../../components/BottomBar'
import MultidayDropDown from '../../components/MultidayDropDown'
import { useActiveSession } from '../../services/hooks/useActiveSession'
import Locations from './Location'
import { userState } from '../../services/user'
import InstructorVerification from '../../components/InstructorVerification'
import { useGetConfig } from '../../services/hooks/useConfig'

const SessionDetails = () => {
	const navigate = useNavigate()
	const { state } = useLocation()
	const { activeSession, updateActiveSession } = useActiveSession()
	const { name, date, lmsId, sessionTemplateId, endTime } = activeSession
	const user = useRecoilValue(userState)
	const [instructorId, setInstructorId] = useState('')
	const [startTime, setStartTime] = useState('')
	const [ET, setEndTime] = useState('')
	const [location1, setLocation1] = useState('')
	const [location2, setLocation2] = useState('')
	const [instructorVerificationMessage, setInstructorVerificationMessage] = useState<
		string | null
	>(null)
	const getConfig = useGetConfig()
	const [disableEdit, setDisableEdit] = useState(true)
	const [disableInstructorTakeOver, setDisableInstructorTakeOver] = useState(true)
	const [selectedSession, setSelectedSession] = useState<string>(lmsId)

	useEffect(() => {
		updateActiveSession({ status: 1 })
	}, [updateActiveSession])

	useEffect(() => {
		getConfig?.canEditSession === true && setDisableEdit(false)
		getConfig?.canTakeOverSession === true && setDisableInstructorTakeOver(false)
		setInstructorId(activeSession.instructorId)
		if (getConfig?.canTakeOverSession) {
			if (
				activeSession.instructorId !== user.instructorId &&
				activeSession.actualInstructorId !== user.instructorId
			) {
				setInstructorVerificationMessage('Would you like to replace the instructor?')
			}
		}
		return () => {
			setInstructorVerificationMessage(null)
		}
	}, [activeSession.instructorId, user.instructorId, getConfig, activeSession])

	useEffect(() => {
		const st =
			activeSession.startTime.length === 4
				? `0${activeSession.startTime}`
				: activeSession.startTime
		setStartTime(st)
	}, [activeSession.startTime])

	useEffect(() => {
		const et =
			activeSession.endTime.length === 4 ? `0${activeSession.endTime}` : activeSession.endTime
		setEndTime(et)
	}, [activeSession.endTime])

	useEffect(() => {
		setLocation1(activeSession.location1)
	}, [activeSession.location1])

	useEffect(() => {
		setLocation2(activeSession.location2)
	}, [activeSession.location2])

	const onLeaveField = () => {
		if (!getConfig?.canEditSession) return

		if (
			instructorId === activeSession.instructorId &&
			startTime === activeSession.startTime &&
			location1 === activeSession.location1 &&
			location2 === activeSession.location2
		)
			return
		updateActiveSession({ instructorId, startTime, location1, location2 })
	}

	const onTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setStartTime(event.target.value)
	}

	const toSessionSelection = () => {
		navigate('/sessionSelection')
	}

	const toAttendance = () => {
		if (
			activeSession.instructorId !== user.instructorId &&
			activeSession.actualInstructorId !== user.instructorId
		) {
			navigate('/')
		} else {
			navigate('/attendance')
		}
	}

	const closeInstructorVerification = () => {
		setInstructorVerificationMessage(null)
	}

	const replaceInstructor = () => {
		setInstructorId(user.instructorId)
		updateActiveSession({ actualInstructorId: user.instructorId })
	}

	return (
		<Container disableGutters onBlur={onLeaveField}>
			<InformationBar currentPage="Class Confirmation" displayClass={true} />
			{lmsId.includes('_') && (
				<MultidayDropDown
					selectedSession={selectedSession}
					setSelectedSession={setSelectedSession}
					currentPage="SessionDetails"
				/>
			)}
			<Box sx={{ padding: '40px' }}>
				<TextField
					className="fullWidth"
					id="item-id"
					label="Item ID"
					value={sessionTemplateId}
					disabled
				/>
				<TextField
					className="fullWidth"
					id="session-name"
					label="Class Title"
					value={name}
					disabled
				/>
				<TextField
					className="fullWidth"
					id="instructor"
					label="InstructorID"
					value={activeSession.actualInstructorId || instructorId}
					// onChange={onInstructorChange}
					// disabled={disableInstructorTakeOver}
					disabled={disableEdit}
				/>
				<TextField
					className="fullWidth"
					id="date"
					label="Date"
					type="date"
					value={date}
					disabled
				/>
				<Box sx={{ margin: '10px', display: 'flex', width: '100%' }}>
					<TextField
						className="fullWidth"
						id="startTime"
						label="Start Time"
						type="time"
						value={startTime}
						onChange={onTimeChange}
						sx={{ margin: '0px', marginRight: '10px' }}
						disabled={disableEdit}
					/>
					<TextField
						className="fullWidth"
						id="endTime"
						label="End Time"
						type="time"
						value={ET || startTime}
						sx={{ margin: '0px' }}
						disabled
					/>
				</Box>
				<TextField
					className="fullWidth"
					id="session-id"
					label="Class Id"
					value={lmsId}
					disabled
				/>

				<Locations
					location1={location1}
					location2={location2}
					setLocation1={setLocation1}
					setLocation2={setLocation2}
					summary={false}
					locked={true}
				/>
			</Box>
			<BottomBar
				instructions=""
				buttons={[
					{ label: 'Instructor Class Selection', onClick: toSessionSelection },
					{ label: 'Attendance', onClick: toAttendance },
				]}
			/>
			{instructorVerificationMessage && (
				<InstructorVerification
					title="Instructor Verification"
					message={instructorVerificationMessage}
					updateParent={closeInstructorVerification}
					replaceInstructor={replaceInstructor}
					onLeaveField={onLeaveField}
				/>
			)}
		</Container>
	)
}

export default SessionDetails
